import {Action, createReducer, on} from '@ngrx/store';
import { Model } from '../../models';
import {
  addRecordSuccess,
  deleteRecordSuccess,
  loadRecordsSuccess,
  setActiveSuccess,
  setSmsSuccess,
  updateRecordSuccess
} from './actions';

const initialState: Model[] = null;

const featureReduce = createReducer(
  initialState,
  on(loadRecordsSuccess, (state, {records}) => records),
  on(addRecordSuccess, (state, {record}) => [{...record, active: true}, ...state]),
  on(updateRecordSuccess, (state, {record}) => {
    console.log('state', state, record);
    return state.map(item => item.id === record.id ? {...item, ...record} : item);
  }),
  on(deleteRecordSuccess, (state, {id}) => {
    return state.filter(item => item.id !== id);
  }),
);

export function reducer(state: Model[] | null, action?: Action) {
  return featureReduce(state, action);
}
