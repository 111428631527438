import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {ApiClient} from './core/services/api.service';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiInterceptor} from './core/interceptors/api.interceptor';
import {registerLocaleData} from '@angular/common';
import {AppProvider} from './core/providers/app.provider';
import {LoaderService} from './core/services/loader.service';
import localeItIt from '@angular/common/locales/it';
import {MenuService} from './core/services/menu.service';
import {SharedModule} from './shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ModalDialogModule} from '../packages/ngx-modal-master';
import {PhotoBoothModule} from '../packages/ngx-photo-booth/photo-booth.module';
import * as moment from 'moment';
// import * as Sentry from '@sentry/angular';

registerLocaleData(localeItIt);

moment.locale('it');

export function appProviderFactory(provider: AppProvider) {
  return () => provider.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    ModalDialogModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PhotoBoothModule,
  ],
  providers: [
    ApiClient,
    AppProvider,
    LoaderService,
    MenuService,
    // {provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [], multi: true},
    {provide: APP_INITIALIZER, useFactory: appProviderFactory, deps: [AppProvider], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'it-IT'},
    // {provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false })},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(trace: Sentry.TraceService) {}
}
