
        <div class="DobInput" [formGroup]="form">
            <select class="form-control DobInput_Giorno" formControlName="giorno">
                <option value="">Giorno</option>
                <option *ngFor="let giorno of giorni" [value]="giorno">{{giorno}}</option>
            </select>
            <select class="form-control DobInput_Mese" formControlName="mese">
                <option value="">Mese</option>
                <option *ngFor="let mese of mesi" [value]="mese">{{mese}}</option>
            </select>
            <input type="number" formControlName="anno" placeholder="Anno" class="form-control DobInput_Anno">
        </div>
    