import { Injectable } from '@angular/core';
import { Actions} from '@ngrx/effects';

@Injectable()
export class Effects {

    constructor(
        private actions$: Actions
    ) {}

}