import { createSelector } from '@ngrx/store';
import { featureSelect } from '../settings';
const ɵ0 = (state) => state.list ? state.list.filter(item => item.isOwner === true) : null;
export const getRecords = createSelector(featureSelect, ɵ0);
const ɵ1 = (state) => state.list ? state.list.filter(item => item.active && item.isOwner === true) : null;
export const getActiveRecords = createSelector(featureSelect, ɵ1);
const ɵ2 = (state, recordId) => state.list.find(record => record.id.toString() === recordId.toString());
export const getRecordById = createSelector(featureSelect, ɵ2);
const ɵ3 = (state) => state.current;
export const getCurrentRecord = createSelector(featureSelect, ɵ3);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
