import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { reducer as listReducer } from './list/reducer';
import { reducer as currentReducer } from './current/reducer';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FeatureEffects } from './list/effects';

import { reducer as errorsReducer } from './errors/reducer';
import { FeatureState } from './settings';
import { ItemModalComponent } from '../components/item-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';

const reducers: ActionReducerMap<FeatureState> = {
    list: listReducer,
    current: currentReducer,
    errors: errorsReducer
}

@NgModule({
    declarations: [ItemModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        StoreModule.forFeature('clienti', reducers),
        EffectsModule.forFeature([FeatureEffects])
    ],
    entryComponents: [ItemModalComponent]
})

export class FeatureStoreModule {
}


