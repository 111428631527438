import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, sortBy } from '../../helpers';

@Pipe({
    name: 'sort',
    pure: false
})

export class SortPipe implements PipeTransform {
    transform(records: any[], order = '', column = ''): any {
        if (!records || order === '' || !order) { return records; } // no array
        if (!column || column === '') { return sortBy(records); } // sort 1d array
        if (records.length <= 1) { return records; } // array with only one item
        return orderBy(records, [column], [order]);
    }
}
