import { createSelector } from '@ngrx/store';
import { featureSelect, FeatureState } from '../settings';

export const getRecords = createSelector(
    featureSelect,
    (state: FeatureState) => state.list ? state.list.filter(item => item.isOwner === true) : null
);

export const getActiveRecords = createSelector(
    featureSelect,
    (state: FeatureState) => state.list ? state.list.filter(item => item.active && item.isOwner === true) : null
);

export const getRecordById = createSelector(
    featureSelect,
    (state: FeatureState, recordId) => state.list.find(record => record.id.toString() === recordId.toString())
);

export const getCurrentRecord = createSelector(
    featureSelect,
    (state: FeatureState) => state.current
);
