import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import {Router} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState} from '../store/store.module';
import { getToken } from '../store/auth/selectors';
import { logout } from '../store/auth/actions';
import {Token} from '../../models/token';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    token: Token | null = null;

    constructor(private router: Router, private store: Store<AppState>) {

        this.store.pipe(select(getToken))
            .subscribe(value => this.token = value);

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return this.store.select(getToken)
            .pipe(
                take(1),
                switchMap( (token) => {

                    let headers = {};

                    // htaccess directory password
                    if (window['apiAuth'] && window['apiAuth'] !== '') {
                        headers = { Authorization: `Basic ${window['apiAuth']}`};
                    }

                    if (token) {
                        headers = {...headers, ['X-Access-Token']: `Bearer ${token.access_token}`};
                    }

                    const clonedReq = request.clone({
                        setHeaders: headers
                    });

                    return next.handle(clonedReq)
                        .pipe(
                            catchError((error) => {
                                // expired token
                                if (error.status === 400) {
                                    this.store.dispatch(logout());
                                }
                                return throwError(error);
                                // return of(error);
                            })
                        );
                })
            );

    }
}
