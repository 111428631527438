import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import * as routerActions from './actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class Effects {

    goEffect$ = createEffect(() => this.actions$
        .pipe(
            ofType(routerActions.go),
            tap(action => {
                this.router.navigateByUrl(action.path)
            })
        ), { dispatch: false }
    )

    constructor(
        private router: Router,
        private actions$: Actions
    ) {}

}