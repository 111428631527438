import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getAccount, getAccountSuccess, updateSettings, updateSettingsFail, updateSettingsSuccess, } from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Account } from '../../../models/Account';
import { AccountService } from '../../services/account.service';

@Injectable()
export class Effects {

    getAccount$ = createEffect(() => this.actions$.pipe(
        ofType(getAccount),
        switchMap(() => this.service.get()
            .pipe(
                map((account: Account) => getAccountSuccess({ account })),
                // catchError(() => of(getAccountFail()))
            )
        )
    ))

    updateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(updateSettings),
        switchMap(({ data },state) => this.service.updateSettings(data)
            .pipe(
                map((account: Account) => updateSettingsSuccess({ data }))
                // catchError(() => (updateSettingsFail()))
            )
        )
    ))

    constructor(
        private service: AccountService,
        private actions$: Actions
    ){}

}