import { ApiService } from '../../../../core/store/base/api-service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class FeatureService extends ApiService {

    apiSegment = 'categorie-prodotti';

}