
    <div class="modal-header">
      <h4 class="modal-title">{{record ? 'Modifica cliente' : 'Nuovo cliente'}}</h4>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" action="" (submit)="handleSubmit()">

        <div *ngIf="errors && errors.message" class="alert alert-danger">{{ errors.message }}</div>

        <div class="form-group row">
          <label class="col-form-label">Cognome</label>
          <div class="control-input">
            <input class="form-control" type="text" formControlName="cognome"
                   name="cognome"
                   [ngClass]="{'is-invalid': errors?.cognome }"
                   required>
            <span *ngIf="errors?.cognome"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.cognome[0] }}
                </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label">Nome</label>
          <div class="control-input">
            <input class="form-control" type="text" formControlName="nome"
                   name="nome"
                   [ngClass]="{'is-invalid': errors?.nome }"
                   required>
            <span *ngIf="errors?.nome"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.nome[0] }}
                </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label">Telefono</label>
          <div class="control-input">
            <input class="form-control" type="text" formControlName="telefono"
                   name="telefono"
                   [ngClass]="{'is-invalid': errors?.telefono }"
                   required>
            <span *ngIf="errors?.telefono"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.telefono[0] }}
                </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label">Indirizzo email</label>
          <div class="control-input">
            <input class="form-control" type="email" formControlName="email"
                   name="email"
                   [ngClass]="{'is-invalid': errors?.email }">
            <span *ngIf="errors?.email"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.email[0] }}
                </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label">Residenza</label>
          <div class="control-input">
            <textarea class="form-control" type="text" formControlName="residenza"
                   name="residenza"
                   [ngClass]="{'is-invalid': errors?.residenza }"
                      required></textarea>
            <span *ngIf="errors?.residenza"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.residenza[0] }}
                </span>

            <small>Inserire indirizzo completo: via, città, CAP e provincia</small>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label">Data di nascita</label>
          <div class="control-input">
            <app-dob-input formControlName="dob"></app-dob-input>
            <span *ngIf="errors?.dob"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.dob[0] }}
                </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label">Sesso</label>
          <div class="control-input">
            <select name="genre" formControlName="genre"
                    [ngClass]="{'is-invalid': errors?.genre }" class="form-control">
              <option [value]="null">Seleziona</option>
              <option value="Maschio">Maschio</option>
              <option value="Femmina">Femmina</option>
            </select>
            <span *ngIf="errors?.genre"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.genre[0] }}
                </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label">SMS abilitati</label>
          <div class="control-input">
                    <span class="switch">
                        <label>
                            <input formControlName="sms" type="checkbox">
                            <span></span>
                        </label>
                    </span>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="modal.dismiss()" class="btn btn-default">Chiudi</button>
      <button (click)="handleSubmit()" [disabled]="false" class="btn btn-primary">Salva</button>
    </div>
  