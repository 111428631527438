import { Injectable } from '@angular/core';
import { FeatureService } from './service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { parseHttpResponse } from '../../../../shared/helpers';
import { Store } from '@ngrx/store';
import { Prodotto } from '../../../../models/Prodotto';
import * as actions from './actions';
import * as errorActions from '../errors/actions';
import { getRecords } from './selectors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemModalComponent as ModalComponent } from '../../components/item-modal.component';
import { getErrors } from '../errors/selectors';
import { loadRecords } from './actions';

@Injectable()
export class FeatureEffects {

    loadRecords$ = createEffect(() => this.actions$.pipe(
        ofType(actions.loadRecords),
        withLatestFrom(this.store.select(getRecords)),
        filter(([action, state]) => (action && action.reload === true) || state === null),
        switchMap(() => this.service.all()
            .pipe(
                map((records: Prodotto[]) => actions.loadRecordsSuccess({ records })),
                catchError(response => of(actions.loadRecordsFail(parseHttpResponse(response))))
            )
        )
    ));

    saveRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.saveRecord),
        mergeMap(({record}) => {
            return of(
                errorActions.clear(),
                record && record.hasOwnProperty('id') && record.id ?
                    actions.updateRecord({record}) :
                    actions.addRecord({record})
            );
        })
    ));

    addRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.addRecord),
        mergeMap(({record}) => this.service.create(record)
            .pipe(
                switchMap((result: any) => [
                    actions.addRecordSuccess({ record: result.record }),
                    actions.closeModal()
                ]),
                catchError(response => of(errorActions.set(parseHttpResponse(response))))
            )
        )
    ));

    updateRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.updateRecord),
        mergeMap(({record}) => this.service.update(record.id, record)
            .pipe(
                switchMap((result: any) => [
                    actions.updateRecordSuccess({ record: result.record }),
                    actions.loadRecords({ reload: true }),
                    actions.closeModal()
                ]),
                catchError(response => of(errorActions.set(parseHttpResponse(response))))
            )
        )
    ));

    deleteRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.deleteRecord),
        switchMap(({id}) => this.service.delete(id)
            .pipe(
                switchMap((result: any) => [
                    // loadRecords({ force: true }),
                    actions.deleteRecordSuccess({id})
                ]),
                // catchError(() => of(loadRecordsFail()))
            )
        )
    ));

    showModal$ = createEffect(() => this.actions$.pipe(
        ofType(actions.showModal),
        withLatestFrom(this.store.select(getRecords)),
        tap(([action, records]) => {
            const record = action.id ? records.find(record => record.id === action.id) : null;
            const modal = this.modal.open(ModalComponent);
            const subscription = this.store.select(getErrors).subscribe(errors => modal.componentInstance.errors = errors);
            modal.result.then(() => null).catch(() => {
                this.store.dispatch(errorActions.clear());
                subscription.unsubscribe();
            });
            modal.componentInstance.userId = action.userId;
            modal.componentInstance.record = record;
        })
    ), { dispatch: false });

    closeModal$ = createEffect(() => this.actions$.pipe(
        ofType(actions.closeModal),
        tap(() => {
            console.log('close');
            this.modal.dismissAll();
        })
    ), { dispatch: false });

  updateSort$ = createEffect(() => this.actions$.pipe(
    ofType(actions.updateSort),
    switchMap(({ids}) => this.service.updateSort(ids)
      .pipe(
        map(() => loadRecords({ reload: true }))
      )
    )
  ));


  constructor(
        private service: FeatureService,
        private modal: NgbModal,
        private store: Store,
        private actions$: Actions
    ) {}

}
