import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
const ɵ0 = () => import("./features/dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./features/clienti/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ2 = () => import("./features/assistenza/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ3 = () => import("./features/preventivi/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ4 = () => import("./features/risorse/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ5 = () => import("./features/academy-events/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ6 = () => import("./features/prodotti/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ7 = () => import("./features/faq/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ8 = () => import("./features/documenti/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ9 = () => import("./features/notifications/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ10 = () => import("./features/statistiche/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ11 = () => import("./features/admin/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ12 = () => import("./features/account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ13 = () => import("./features/auth/auth.module.ngfactory").then(m => m.AuthModuleNgFactory), ɵ14 = () => import("./features/messaggistica/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory);
const routes = [
    { path: '', loadChildren: ɵ0, canActivate: [AuthGuard] },
    { path: 'clienti', loadChildren: ɵ1, canActivate: [AuthGuard] },
    { path: 'assistenza', loadChildren: ɵ2, canActivate: [AuthGuard] },
    { path: 'preventivi', loadChildren: ɵ3, canActivate: [AuthGuard] },
    { path: 'risorse', loadChildren: ɵ4, canActivate: [AuthGuard] },
    { path: 'academy-events', loadChildren: ɵ5, canActivate: [AuthGuard] },
    { path: 'prodotti', loadChildren: ɵ6, canActivate: [AuthGuard] },
    { path: 'faq', loadChildren: ɵ7, canActivate: [AuthGuard] },
    { path: 'documenti', loadChildren: ɵ8, canActivate: [AuthGuard] },
    { path: 'notifications', loadChildren: ɵ9, canActivate: [AuthGuard] },
    { path: 'statistiche', loadChildren: ɵ10, canActivate: [AuthGuard] },
    { path: 'admin', loadChildren: ɵ11, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: ɵ12, canActivate: [AuthGuard] },
    { path: 'auth', loadChildren: ɵ13 },
    { path: 'messaggistica', loadChildren: ɵ14 },
    { path: '**', redirectTo: 'search' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
