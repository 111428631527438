import { ApiClient } from '../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api.service";
export class ApiService {
    constructor(http) {
        this.http = http;
        this.apiSegment = '[segment]';
    }
    all() {
        console.log('apiSegment', this.apiSegment);
        return this.http.get(`${this.apiSegment}`);
    }
    create(data) {
        return this.http.post(`${this.apiSegment}`, data);
    }
    item(id) {
        return this.http.get(`${this.apiSegment}/${id}`);
    }
    itemCheckup(id) {
        return this.http.get(`${this.apiSegment}/${id}/checkup`);
    }
    update(id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`${this.apiSegment}/${id}`, data);
    }
    delete(id) {
        return this.http.delete(`${this.apiSegment}/${id}`);
    }
    getQueryString(parameters = {}, hideEmpty = true) {
        return Object.keys(parameters).filter(key => parameters[key]).map(key => `${key}=${parameters[key]}`).join('&');
    }
    updateSort(ids) {
        return this.http.post(`${this.apiSegment}/sort`, {
            _method: 'PUT',
            ids
        });
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.ApiClient)); }, token: ApiService, providedIn: "root" });
