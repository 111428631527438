import { createAction, props } from '@ngrx/store';
import { Account } from '../../../models/Account';

const area = '[Account]'

/**
 * Get account
 */

export const getAccount = createAction(
    `${area} Get`,
);

export const getAccountSuccess = createAction(
    `${area} Get account success`,
    props<{ account: Account }>()
);

export const getAccountFail = createAction(
    `${area} Get account fail`
);

export const clearAccountData = createAction(
    `${area} Clear account data`
);


/**
 * Update settings
 */

export const updateSettings = createAction(
    `${area} Update settings`,
    props<{ data: any }>()
);

export const updateSettingsSuccess = createAction(
    `${area} Update settings success`,
    props<{ data: any }>()
);

export const updateSettingsFail = createAction(
    `${area} Update settings fail`
);

/**
 * Update account
 */

export const updateAccount = createAction(
    `${area} Get`,
    props<{ account: Account }>()
);

export const updateAccountSuccess = createAction(
    `${area} Update account success`,
    props<{ account: Account }>()
);

export const updateAccountFail = createAction(
    `${area} Update account fail`
);

/**
 * Decrease unread message
 */

export const decreaseUnreadMessages = createAction(
    `${area} Readed message`,
    props<{ number: number }>()
);

export const setUnreadMessages = createAction(
    `${area} Set readed message`,
    props<{ number: number }>()
);

export const setReadNotification = createAction(
    `${area} Set readed notification`
);

export const setAllReadNotification = createAction(
    `${area} Set all readed notification`
);
