
        <div class="MessagesContainer">
            <div class="MessagesContainer_List" #messagesView>
                <div class="messages">
                    <div *ngIf="!isAdmin && record.cliente_id" class="mb-5 rounded p-5 font-weight-bold font-size-lg bg-light-primary">
                        La richiesta si riferisce a: {{ record.cliente.cognome }} {{ record.cliente.nome }}. <br />
                        L'assistenza potrà vederne la scheda completa in forma anonima.
                    </div>

                    <div *ngIf="isAdmin" class="mb-5 rounded p-5 font-weight-bold font-size-lg bg-light-dark">
                      <p>Richiesta inviata da <strong>{{ detail.ticket.user.nome }} {{ detail.ticket.user.cognome }}</strong> il <strong>{{ detail.ticket.created_at | dateFormat: 'dd/MM/yyyy' }}</strong></p>
                      <ng-container *ngIf="record.cliente_id">
                        L'operatore ha selezionato un cliente per questa richiesta, cliccando sul bottone "Visualizza cliente" sarà possibile vedere tutti i trico check e le foto:<br>
                        <button type="button" class="btn btn-sm btn-default mt-2" (click)="handleShowSchedaCliente()">Visualizza cliente</button>
                      </ng-container>
                    </div>

                    <div *ngFor="let item of messages" class="d-flex flex-column mb-5 align-items-start" [ngClass]="{'align-items-end': item.type === 'owner'}">
                        <div class="mt-2 rounded p-5 font-weight-bold font-size-lg text-left max-w-400px" [ngClass]="{'bg-light-primary': item.type === 'owner', 'text-dark': item.type !== 'owner', 'bg-light-dark': item.type !== 'owner' }">
                            {{ item.message }}
                        </div>
                      <div *ngIf="item.photos.length" class="MessagesContainer_Photos" [ngClass]="{'justify-content-end': item.type === 'owner'}">
                        <a target="_blank" [href]="foto.fullUrl" *ngFor="let foto of item.photos">
                          <img [src]="foto.fullUrl" />
                        </a>
                      </div>
                      <div class="d-flex align-items-center mt-2">
                        <span class="text-muted font-size-sm ml-2"><ng-container *ngIf="item.type !== 'owner'">{{item.user_name}} - </ng-container>{{ item.created_at | dateFormat: 'dd/MM/yyyy HH:mm' }}</span>
                      </div>
                    </div>
                </div>
            </div>
            <div class="MessagesContainer_Form">
              <ng-container *ngIf="archived" class="text-center">Non è possibile rispondere alla richiesta in quanto risulta archiviata</ng-container>
                <form [formGroup]="myForm" (submit)="handleSubmit()"  action="" *ngIf="!archived">
                    <textarea [ngClass]="{'is-invalid': errors?.message }" formControlName="message" class="form-control border-0 p-0" placeholder="Risposta..."></textarea>
                  <span *ngIf="errors?.message"
                        class="invalid-feedback"
                        role="alert">
                    {{ errors?.message[0] }}
                </span>
                </form>
            </div>
        </div>
    