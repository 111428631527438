import { Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Notification {
    success: boolean;
    message: string;
}

@Injectable({
    providedIn: 'root',
})

export class NotificationService {

    // public _items: Notification[];
    // public items: BehaviorSubject<Notification[]>;
    // items: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    _item = null;
    item: BehaviorSubject<any> = new BehaviorSubject(null);

    show(dataObj: Notification, autohide = true) {

        this._item = dataObj;
        this.item.next(this._item);
        if (autohide) {
            setTimeout(() => {
                this.hide()
            }, 4000);
        }

    }

    getItem() {
        return this._item;
    }

    hide() {

        this._item = null;
        this.item.next(this._item);

    }

}
