import { createFeatureSelector } from '@ngrx/store';
import { HttpStatus } from '../../../models/http-status';
import { Model } from '../models';

export const featureName = 'Preventivi';

export interface FeatureState {
  list: Model[],
  errors: HttpStatus,
}

export const featureSelect = createFeatureSelector<FeatureState>('preventivi');
