import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getRecords as getCategorieProdotto } from '../../features/prodotti/store/categories/selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/store.module';
import { loadRecords as loadCategorieProdotto } from '../../features/prodotti/store/categories/actions';
import { Prodotto } from '../../models/Prodotto';
import { FeatureService } from '../../features/prodotti/feature.service';

@Component({
  selector: 'app-linee-prodotto-selector',
  template: `
    <select name="" class="form-control" (change)="handleSelect($event)">
      <option value="">Seleziona linea</option>
      <ng-container *ngFor="let linea of lineeProdotto$ | async"><option *ngIf="linea.products_count > 0" [value]="linea.id">{{ linea.name }}</option></ng-container>
    </select>

    <div *ngIf="records.length" class="mt-3 mb-3">
      <p>Seleziona i prodotti e clicca su "Aggiungi"</p>
      <div *ngFor="let product of records">
        <label [for]="'product' + product.id">
          <input [id]="'product' + product.id" [checked]="selectedIds.indexOf(product.id) !== -1" type="checkbox" [value]="product.id" (change)="handleToggleProduct($event, product)"> {{ product.name }}
        </label>
      </div>
      <button type="button" (click)="handleAddProducts()" class="btn btn-sm btn-secondary">Aggiungi</button>
    </div>


  `
})

export class LineeProdottoSelectorComponent {

  @Input() filtro: 'trattamento' | 'ordine' | null = null;
  @Input() vendita: boolean = false;

  lineeProdotto$ = this.store.select(getCategorieProdotto)
  records: Prodotto[] = [];

  selected: Prodotto[] = [];
  selectedIds: number[] = [];

  constructor(private store: Store<AppState>, private service: FeatureService) {
    this.store.dispatch(loadCategorieProdotto({}))
  }

  @Output() addProducts: EventEmitter<Prodotto[]> = new EventEmitter<Prodotto[]>();

  handleSelect($event: Event) {

    this.records = [];
    if ($event.target['value']) {
      this.service.all(this.filtro, $event.target['value']).subscribe((records: Prodotto[]) => {
        this.records = records;
      })
    }

  }

  handleToggleProduct($event: Event, record: Prodotto) {

    const checked = event.target['checked'];
    if (checked && this.selectedIds.indexOf(record.id) === -1) {
      this.selected = [...this.selected, record];
      this.selectedIds = [...this.selectedIds, record.id];
    } else if (!checked && this.selectedIds.indexOf(record.id) !== -1) {
      this.selected = [...this.selected.filter(item => item.id !== record.id)];
      this.selectedIds = [...this.selectedIds.filter(item => item !== record.id)];
    }

  }

  handleAddProducts() {

    this.addProducts.emit(this.selected);
    this.selected = [];
    this.selectedIds = [];

  }

}
