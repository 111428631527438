import { NgModule } from '@angular/core';
import { DataTableComponent } from './data-table.component';
import { DataTableRowColumnDirective, DataTableRowDirective } from './data-table.directive';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [
    DataTableComponent,
    DataTableRowDirective,
    DataTableRowColumnDirective,
  ],
  imports: [
    CommonModule,
    DragDropModule
  ],
  exports: [
    DataTableComponent,
    DataTableRowDirective,
    DataTableRowColumnDirective,
  ]
})
export class DataTableModule { }
