import { Component, Input } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { isMenuHidden } from '../store/ui/selectors';
import { toggleMenu } from '../store/ui/actions';
import { can, getPermissions, getSmshostingIsActive, getUnreadMessages } from '../store/account/selectors';

@Component({
  selector: 'app-menu',
  styles: [`
    hr {
      background-color: #4c4e6f;
      height: 2px;
      border: none;
      display: block;
      margin: 5px 0;
    }

  `],
  // host: {
  //     'class' : ''
  // },
  template: `
    <!--begin::Brand-->
    <aside class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
           [ngClass]="{'aside-on': !isMenuHidden}">
      <div class="brand flex-column-auto" id="kt_brand">
        <!--begin::Logo-->
        <a routerLink="/" class="brand-logo">
          <img alt="Logo" src="/assets/logo-alt.png" height="60"/>
        </a>
        <span style="position: absolute; top: 5px; margin-left: -60px" class="badge-env badge badge-danger">AREA DI TEST</span>
      </div>
      <!--end::Brand-->
      <!--begin::Aside Menu-->
      <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
        <!--begin::Menu Container-->
        <div id="kt_aside_menu" class="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1"
             data-menu-dropdown-timeout="500">
          <!--begin::Menu Nav-->
          <ul class="menu-nav">
            <li routerLinkActive="menu-item-active" [routerLinkActiveOptions]="{exact: true}"
                class="menu-item " aria-haspopup="true">
              <a routerLink="/" class="menu-link">
                <span class="menu-text">Home</span>
              </a>
            </li>
            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true">
              <a routerLink="/dashboard/trattamenti" class="menu-link">
                <span class="menu-text">Riepilogo attività</span>
              </a>
            </li>
            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true" *ngIf="_can('calendario')">
              <a routerLink="/dashboard/agenda-appuntamenti" class="menu-link">
                <span class="menu-text">Agenda appuntamenti</span>
              </a>
            </li>

            <li>
              <hr>
            </li>

            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true">
              <a routerLink="/clienti" class="menu-link">
                <span class="menu-text">Rubrica clienti</span>
              </a>
            </li>
            <!--
            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true">
              <a routerLink="/appuntamenti-da-pianificare" class="menu-link">
                <span class="menu-text">Appuntamenti da pianificare</span>
              </a>
            </li>
            -->

            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true" *ngIf="_can('messaggistica')">
              <a routerLink="/messaggistica" class="menu-link">
                                <span class="menu-text">
                                    Messaggistica
                                    <span *ngIf="!isSmshostingActive" class="flaticon2-warning text-danger ml-3"></span>
                                </span>

              </a>
            </li>
            <li routerLinkActive="menu-item-active" class="menu-item" aria-haspopup="true">
              <a routerLink="/documenti" class="menu-link">
                <span class="menu-text">Documenti personali</span>
              </a>
            </li>
            <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
              <a routerLink="/prodotti" class="menu-link">
                <span class="menu-text">Prodotti</span>
              </a>
            </li>
            <li routerLinkActive="menu-item-active" class="menu-item "
                aria-haspopup="true">
              <a routerLink="/preventivi" class="menu-link">
                <span class="menu-text">Preventivi</span>
              </a>
            </li>
            <ng-container *ngIf="!_can('gestione')">
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/faq" class="menu-link">
                  <span class="menu-text">F.A.Q.</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/risorse" class="menu-link">
                  <span class="menu-text">Risorse</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item "
                  aria-haspopup="true"
                  *ngIf="_can('assistenza')"
              >
                <a routerLink="/assistenza" class="menu-link">
                  <span class="menu-text">Richieste assistenza</span>
                  <span class="menu-label" *ngIf="unreadMessages > 0">
                                      <span class="label label-rounded label-primary">{{ unreadMessages }}</span>
                                  </span>
                </a>
              </li>

              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/academy-events" class="menu-link">
                  <span class="menu-text">MyAcademy</span>
                </a>
              </li>
            </ng-container>
            <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true" *ngIf="_can('statistiche')">
              <a routerLink="/statistiche" class="menu-link">
                <span class="menu-text">Statistiche</span>
              </a>
            </li>

            <ng-container *ngIf="_can('gestione')">
              <li class="menu-section">
                <h4 class="menu-text">Gestione portale</h4>
                <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/faq" class="menu-link">
                  <span class="menu-text">F.A.Q.</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/risorse" class="menu-link">
                  <span class="menu-text">Risorse</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/academy-events" class="menu-link">
                  <span class="menu-text">MyAcademy</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/admin/tipologie-trattamenti" class="menu-link">
                  <span class="menu-text">Tipologie trattamenti</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/admin/utenti" class="menu-link">
                  <span class="menu-text">Operatori</span>
                </a>
              </li>
              <li routerLinkActive="menu-item-active" class="menu-item " aria-haspopup="true">
                <a routerLink="/assistenza" class="menu-link">
                  <span class="menu-text">Richieste assistenza</span>
                  <span class="menu-label" *ngIf="unreadMessages > 0">
                      <span class="label label-rounded label-primary">{{ unreadMessages }}</span>
                  </span>
                </a>
              </li>
            </ng-container>

          </ul>
          <!--end::Menu Nav-->
        </div>
        <!--end::Menu Container-->
      </div>
      <!--end::Aside Menu-->
    </aside>
    <div *ngIf="!isMenuHidden" class="aside-overlay" (click)="this.toggleMenu()"></div>

  `
})

export class MenuComponent {

  @Input() vertical = true;
  unreadMessages = 0;
  isMenuHidden = false;
  permissions = [];
  isSmshostingActive = null;

  constructor(private menuService: MenuService, private store: Store) {

    this.store.select(getPermissions).subscribe(permissions => this.permissions = permissions)
    this.store.select(getUnreadMessages).subscribe(number => this.unreadMessages = number)
    this.store.select(getSmshostingIsActive).subscribe(value => this.isSmshostingActive = value)
    this.store.select(isMenuHidden)
      .pipe(
        map(value => value),
        distinctUntilChanged()
      ).subscribe(isMenuHidden => {
      this.isMenuHidden = isMenuHidden;
    })

  }

  handleClose() {
    this.menuService.hide();
  }

  toggleMenu() {

    this.store.dispatch(toggleMenu({value: !this.isMenuHidden}))

  }

  _can(permission) {
    return this.permissions.length ? this.permissions.indexOf(permission) !== -1 : false;
  }

}
