import { Injectable } from '@angular/core';
import { FeatureService } from '../../feature.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as actions from './actions';
import { getRecords } from './selectors';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { parseHttpResponse } from '../../../../shared/helpers';
import { Store } from '@ngrx/store';
import { Model } from '../../models';

import { PreventivoModalComponent } from '../../components/preventivo-modal.component';
import * as errorActions from '../errors/actions';
import { clear } from '../errors/actions';

@Injectable()
export class FeatureEffects {

  loadRecords$ = createEffect(() => this.actions$.pipe(
    ofType(actions.loadRecords),
    withLatestFrom(this.store.select(getRecords)),
    filter(([action, state]) => (action && action.reload === true) || state === null),
    switchMap(() => this.service.all()
      .pipe(
        map((records: Model[]) => actions.loadRecordsSuccess({ records })),
        catchError(response => of(actions.loadRecordsFail(parseHttpResponse(response))))
      )
    )
  ));

  saveRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.saveRecord),
        mergeMap(({record}) => {
            this.store.dispatch(clear());
            if (record && record.hasOwnProperty('id') && record.id)
                return of(actions.updateRecord({record}))
            else
                return of(actions.addRecord({record}))
        })
    ))

    addRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.addRecord),
        mergeMap(({record}) => this.service.create(record)
            .pipe(
                switchMap((result: any) => [
                  actions.addRecordSuccess({ record: result.record }),
                  actions.closeModal(),
                ]),
                catchError(response => of(errorActions.set(parseHttpResponse(response))))
            )
        )
    ))

    updateRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.updateRecord),
        mergeMap(({record}) => this.service.update(record.id, record)
            .pipe(
              switchMap((result: any) => [
                actions.updateRecordSuccess({ record: result.record }),
                actions.closeModal(),
              ]),
                catchError(response => of(errorActions.set(parseHttpResponse(response))))
            )
        )
    ))

    deleteRecord$ = createEffect(() => this.actions$.pipe(
        ofType(actions.deleteRecord),
        switchMap(({id}) => this.service.delete(id)
            .pipe(
                map(() => actions.deleteRecordSuccess({id})),
                // catchError(() => of(loadRecordsFail()))
            )
        )
    ))

    showModal$ = createEffect(() => this.actions$.pipe(
      ofType(actions.showModal),
      switchMap(action => (action.id ? this.service.item(action.id) : of(null))),
      tap((record: Model | null) => {
          const modal = this.modal.open(PreventivoModalComponent);
          if (record) {
            modal.componentInstance.record$.next(record);
          }
      })
    ), { dispatch: false })

    closeModal$ = createEffect(() => this.actions$.pipe(
      ofType(actions.closeModal),
      tap(() => {
        this.modal.dismissAll();
      })
    ), { dispatch: false })

    constructor(
        private service: FeatureService,
        private store: Store,
        private actions$: Actions,
        private modal: NgbModal
    ){}

}
