import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class NotificationService {
    constructor() {
        // public _items: Notification[];
        // public items: BehaviorSubject<Notification[]>;
        // items: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
        this._item = null;
        this.item = new BehaviorSubject(null);
    }
    show(dataObj, autohide = true) {
        this._item = dataObj;
        this.item.next(this._item);
        if (autohide) {
            setTimeout(() => {
                this.hide();
            }, 4000);
        }
    }
    getItem() {
        return this._item;
    }
    hide() {
        this._item = null;
        this.item.next(this._item);
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
