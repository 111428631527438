import { ApiClient } from '../../../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'clienti';
    }
    all(clienteId, trattamentoId) {
        return this.http.get(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}`);
    }
    create(clienteId, data) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento`, data);
    }
    update(clienteId, trattamentoId, data) {
        data = Object.assign({}, data, {
            '_method': 'PUT'
        });
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}`, data);
    }
    appuntamenti(clienteId, trattamentoId = null) {
        if (trattamentoId)
            return this.http.get(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti`);
        else
            return this.http.get(`${this.apiSegment}/${clienteId}/appuntamenti`);
    }
    addAppuntamento(clienteId, trattamentoId, data = null) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti`, data);
    }
    updateAppuntamento(clienteId, trattamentoId, appuntamentoId, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, {
                '_method': 'PUT'
            });
        }
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti/${appuntamentoId}`, data);
    }
    deleteAppuntamento(clienteId, trattamentoId, appuntamentoId) {
        return this.http.delete(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti/${appuntamentoId}`);
    }
    annulla(clienteId, trattamentoId, appuntamentoId) {
        return this.http.put(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti/${appuntamentoId}/annulla`, {
            '_method': 'PUT',
            'stato': 'deleted'
        });
    }
    cicloTrattamento(clienteId, tipologia) {
        return this.http.get(`${this.apiSegment}/${clienteId}/${tipologia}`);
    }
    sendSms(clienteId, trattamentoId) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/sendSms`, {});
    }
    delete(clienteId, trattamentoId) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}`, {
            '_method': 'DELETE'
        });
    }
    appuntamento(clienteId, trattamentoId, appuntamentoId) {
        return this.http.get(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti/${appuntamentoId}`);
    }
    archivia(clienteId, trattamentoId) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/archivia`, {});
    }
    stampa(clienteId, trattamentoId) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/stampa`);
    }
    setDone(clienteId, trattamentoId, appuntamentoId) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoId}/appuntamenti/${appuntamentoId}/done`, {});
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
