import {Action, createReducer, on} from '@ngrx/store';
import {Cliente} from '../../../../models/Cliente';
import {
  addRecordSuccess,
  deleteRecordSuccess,
  loadRecordsSuccess,
  setActiveSuccess,
  setSmsSuccess,
  updateRecordSuccess
} from './actions';
import {logoutSuccess} from '../../../../core/store/auth/actions';

const initialState: Cliente[] = null;

const featureReduce = createReducer(
  initialState,
  on(loadRecordsSuccess, (state, {records}) => records),
  on(addRecordSuccess, (state, {record}) => [{...record, active: true}, ...state]),
  on(updateRecordSuccess, (state, {record}) => state.map(item => item.id === record.id ? {...item, ...record} : item)),
  on(deleteRecordSuccess, (state, {id}) => {
    return state.filter(item => item.id !== id);
  }),
  on(setActiveSuccess, (state, {id, value}) => state.map(item => item.id === id ? {...item, active: value} : item)),
  on(setSmsSuccess, (state, {id, value}) => state.map(item => item.id === id ? {...item, sms: value} : item)),
  on(logoutSuccess, () => initialState),
);

export function reducer(state: Cliente[] | null, action?: Action) {
  return featureReduce(state, action);
}
