import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/store.module';
import { toggleMenu } from '../store/ui/actions';
import { isMenuHidden } from '../store/ui/selectors';
import { getShopName, getUnreadNotifications, getUserName } from '../store/account/selectors';
import { logout } from '../store/auth/actions';

@Component({
    selector: 'app-topbar',
    styles: [`
        @media only print {
            #kt_header {
                display: none;
            }
        }
    `],
    template: `

        <div id="kt_header" class="header header-fixed">
            <div class="container-fluid d-flex align-items-center justify-content-between">
                <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                  <div>
                    <button class="btn btn-primary" (click)="showCamHandler.emit()">Apri MICROCAMERA</button>
                  </div>
                </div>
                <div class="topbar justify-content-between">
                    <!--begin::User-->
                    <div class="topbar-logo">
                        <button (click)="handleToggleMenu()" class="btn p-0 burger-icon" id="kt_header_mobile_toggle">
                            <span></span>
                        </button>
                        <img alt="Logo" src="/assets/logo.png">
                    </div>
                    <div class="topbar-item">

                      <a routerLink="/notifications" class="btn">
                        <i class="fa fa-bell p-0"></i>
                          <span *ngIf="unreadNotifications > 0" class="badge rounded-pill badge-secondary ml-1">{{unreadNotifications}}</span>
                      </a>

                        <button mat-button [matMenuTriggerFor]="userMenu"
                                class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                                id="kt_quick_user_toggle">
                            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{userNameFull}}</span>
                            <span class="symbol symbol-35 symbol-light-primary">
                               <span class="symbol-label font-size-h5 font-weight-bold"><i class="mdi mdi-account" style="padding-top: 7px"></i></span>
                            </span>
                        </button>
                        <mat-menu #userMenu="matMenu" xPosition="before" x-placement="bottom-end"
                                  [class]="'menu-utente'">
                            <div class="navi navi-spacer-x-0 pt-5">
                                <a routerLink="/account" class="navi-item px-8"><div class="navi-link"><div class="navi-text"><div class="font-weight-bold">Account</div></div></div></a>
                                <a routerLink="/account/password" class="navi-item px-8"><div class="navi-link"><div class="navi-text"><div class="font-weight-bold">Modifica password</div></div></div></a>
                                <a routerLink="/account/calendario" class="navi-item px-8"><div class="navi-link"><div class="navi-text"><div class="font-weight-bold">Impostazioni</div></div></div></a>
                                <div class="navi-separator mt-3"></div>
                                <div class="navi-footer px-8 py-5">
                                    <button (click)="handleLogout()" class="btn btn-light-primary font-weight-bold" style="width: 100%">
                                        Esci
                                    </button>
                                </div>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    `,
})

export class TopbarComponent {

    isMenuHidden = false;
    userName = null
    unreadNotifications = 0;
    userNameFull = null
    @Output() showCamHandler = new EventEmitter()

    constructor(
        private router: Router,
        private store: Store<AppState>
    ) {
        this.store.select(isMenuHidden).subscribe(value => this.isMenuHidden = value);
        this.store.select(getUnreadNotifications).subscribe(value => this.unreadNotifications = value);
        this.store.select(getShopName).subscribe(value => {
            this.userNameFull = value;
        });
    }

    handleLogout() {

        this.store.dispatch(logout());

    }

    handleToggleMenu() {
        this.store.dispatch(toggleMenu({value: !this.isMenuHidden}))
    }

}
