import { ApiClient } from '../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class AccountService {
    constructor(http) {
        this.http = http;
    }
    get() {
        return this.http.get('account');
    }
    update(data) {
        return this.http.post(`account`, data);
    }
    getTipologieTrattamenti() {
        return this.http.get('account/tipologie-trattamenti');
    }
    getCategorieTrattamenti(tipologia = 'trattamento') {
        return this.http.get(`account/categorie-trattamenti?tipologia=${tipologia}`);
    }
    calendar({ date, type, escludiTrattamento = null }) {
        return this.http.get(`account/calendar?date=${date}&type=${type}${escludiTrattamento ? '&escludiTrattamento=' + escludiTrattamento : ''}`);
    }
    searchCalendar({ query }) {
        return this.http.get(`account/calendar?query=${query}`);
    }
    settings() {
        return this.http.get(`account/settings`);
    }
    updateSettings(data) {
        return this.http.put(`account/settings`, { data });
    }
    updatePassword(data) {
        return this.http.put(`account/password`, data);
    }
}
AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.ApiClient)); }, token: AccountService, providedIn: "root" });
