import { Action, createReducer, on } from '@ngrx/store';

import { clear, set } from './actions';
import { HttpStatus } from '../../../../../models/http-status';
import {logoutSuccess} from '../../../../../core/store/auth/actions';

const initialState: HttpStatus = { errors: null };

const featureReducer = createReducer(
    initialState,
    on(clear, (state, action) => ({ errors: null })),
    on(set, (state, action) => ({ ...action })),
    on(logoutSuccess, () => initialState),
);

export function reducer (state: HttpStatus | null, action?: Action) {
    return featureReducer(state, action);
}
