import { Injectable} from '@angular/core';
import { ApiClient } from '../../../../core/services/api.service';

@Injectable({
    providedIn: 'root',
})

export class FeatureService {

    private apiSegment = 'clienti';

    constructor(
        private http: ApiClient
    ) {
    }

    all(clienteId) {

        return this.http.get(`clienti/${clienteId}/checkup`);

    }

    create(clienteId, data) {

        return this.http.post(`clienti/${clienteId}/checkup`, data);

    }

    item(clienteId, id) {

        return this.http.get(`clienti/${clienteId}/checkup/${id}`);

    }

    update(clienteId, id, data) {

        if(data instanceof FormData) {
            data.append('_method', 'PUT');
        } else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }

        return this.http.post(`clienti/${clienteId}/checkup/${id}`, data);

    }

    updateFoto(clienteId, id, data) {

        return this.http.post(`clienti/${clienteId}/checkup/${id}/foto`, data);


    }

    delete(clienteId, id) {

        return this.http.delete(`clienti/${clienteId}/checkup/${id}`);

    }

    pdf(clienteId, id, hideName = false) {
        return this.http.getEndpoint(`clienti/${clienteId}/checkup/${id}/pdf`, {hideName});
    }

}
