import { Component, Input } from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-subarea',
    styles: [`
        @media only print {
            #kt_subheader {
                display: none;
            }
        }
    `],
    template: `
        <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div class="d-flex align-items-center flex-wrap mr-2 SubheaderContent">
                    <button *ngIf="!this.hideBack" (click)="location.back()" class="btn btn-default btn-sm mr-4"><i class="flaticon2-back"></i> Pagina precedente</button>
                    <span class="mr-2">Dove sei:</span>
                    <ng-content></ng-content>
                </div>
                <div class="d-flex align-items-center">
                </div>
            </div>
        </div>
    `
})

export class SubareaComponent {
    @Input() title = 'Dashboard';
    @Input() subtitle = null;
    @Input() hideBack = false;

    constructor(private location: Location) {}

}