import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './actions';
import { State } from './index';

const initialState: State = {
    token: null,
    error: null
};

const authReducer = createReducer(
    initialState,
    on(authActions.saveAuth, ((state, { token }) => ({...state, token, error: null}))),
    on(authActions.loginSuccess, ((state, { token }) => ({...state, token, error: null}))),
    on(authActions.loginFail, ((state, {error}) => ({...state, token: null, error}))),
    on(authActions.logoutSuccess, ((state) => ({token : null, error: null})))
);

export function reducer (state: State | null, action?: Action) {
    return authReducer(state, action);
}