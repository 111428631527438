import { createAction, props } from '@ngrx/store';
import { Model } from '../../models';

const area = '[Preventivi]'

/**
 * Get records actions
 */

export const loadRecords = createAction(
    `${area} Load records`,
    props<{ reload?: true }>()
);

export const loadRecordsSuccess = createAction(
    `${area} Load records success`,
    props<{ records: Model[] }>()
);

export const loadRecordsFail = createAction(
    `${area} Load records fail`,
    props<any>()
);

export const saveRecord = createAction(
    `${area} Save record`,
    props<{ record: Model }>()
);

/**
 * Add record actions
 */

export const addRecord = createAction(
    `${area} Add record`,
    props<{ record: Model }>()
);

export const addRecordSuccess = createAction(
    `${area} Add record success`,
    props<{ record: Model }>()
);

export const addRecordFail = createAction(
    `${area} Add record failed`,
    props<any>()
);

/**
 * Update record actions
 */

export const updateRecord = createAction(
    `${area} Update record`,
    props<{ record: Model }>()
);

export const updateRecordSuccess = createAction(
    `${area} Update record success`,
    props<{ record: Model }>()
);

export const updateRecordFail = createAction(
    `${area} Update record failed`,
    props<any>()
);

/**
 * Delete record actions
 */

export const deleteRecord = createAction(
    `${area} Delete record`,
    props<{ id: number }>()
);

export const deleteRecordSuccess = createAction(
    `${area} Delete record success`,
    props<{ id: number }>()
);


/**
 * Set current record
 */

export const setCurrentRecord = createAction(
    `${area} Set current record`,
    props<{ id: number }>()
);

export const updatePrivacyFile = createAction(
    `${area} Update Privacy File`,
    props<{ id: number, file: any }>()
);

/**
 * Set Active
 */

export const setActive = createAction(
    `${area} Set active record`,
    props<{ id: number, value: boolean }>()
);

export const setActiveSuccess = createAction(
    `${area} Set active success`,
    props<{ id: number, value: boolean }>()
);

/**
 * Set Sms
 */

export const setSms = createAction(
    `${area} Set sms record`,
    props<{ id: number, value: boolean }>()
);

export const setSmsSuccess = createAction(
    `${area} Set sms success`,
    props<{ id: number, value: boolean }>()
);

/**
 * Set Sms promemroia
 */

export const setSmsPromemoria = createAction(
    `${area} Set sms promemoria record`,
    props<{ id: number, value: boolean }>()
);

export const setSmsPromemoriaSuccess = createAction(
    `${area} Set sms promemoria success`,
    props<{ id: number, value: boolean }>()
);

/**
 * Modal
 */

export const showModal = createAction(
  `${area} Open modal`,
  props<{ id?: number }>()
);

export const closeModal = createAction(
  `${area} Close modal`,
);

