import { createAction, props } from '@ngrx/store';
import { HttpStatus } from '../../../../models/http-status';
import { featureName } from '../settings';

const area = `[${featureName}]`;

export const clear = createAction(
    `${area} Clear Errors`,
);

export const set = createAction(
    `${area} Set Errors`,
    props<HttpStatus>()
);
