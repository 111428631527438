import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import isObject from 'lodash.isobject';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { NotificationService } from "../services/notification.service";
import { LoaderService } from "../services/loader.service";
import { Router } from "@angular/router";

@Injectable()

export class ApiInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService, public loaderService: LoaderService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(request.url.indexOf('user/temp-foto') === -1) {
          this.loaderService.show();
        }

        return next.handle(request).pipe(
            map((event: any) => {

                if (event.status === 200 && event.body && event.body.message) {
                    this.notificationService.show({
                        success: true,
                        message: event.body.message
                    });
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {

                if (error.status === 422 && error.hasOwnProperty('error') && error.error.hasOwnProperty('errors').hasOwnProperty('data') && error.error.errors.data[0].indexOf('Esiste un') === 0) {

                    // const confirmed = confirm(error.error.errors.data[0] + '. Vuoi procedere comunque?');

                } else if (error.status === 401 || error.status === 403 || error.status === 404 || error.status === 422 || error.status === 500) {

                  const message = error.error.message ? error.error.message : !isObject(error.error) ? error.error : null;

                    if (message) {
                        this.notificationService.show({
                            success: false,
                            message
                        });
                    }
                }
                return throwError(error);
            })).pipe(
            finalize(() => this.loaderService.hide())
        );

    }
}
