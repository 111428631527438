import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {forkJoin, merge} from 'rxjs';

@Component({
  selector: 'foto-item',
  styles: [`
    .FotoItem {
      position: relative;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .FotoItem--small {
      width: 100px;
      height: 100px;
      margin: 10px;
    }

    .FotoItem--small .FotoItem_Wrapper {
      height: inherit;
    }

    .FotoItem_Wrapper {
      display: block;
      height: 200px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .FotoItem_Buttons {
      position: absolute;
      top: -10px;
      right: 10px;
      display: flex;
    }


    .FotoItem .input-group {
      position: absolute;
      left: 10px;
      bottom: 10px;
      right: 10px;
      width: auto;
    }

    .FotoItem .input-group span {
      background: #fff;
      padding: 3px 5px;
      border-radius: 3px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .FotoItem_Deleted {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .FotoItem-deleted {
      /*opacity: 0.2;*/
    }
  `],
  template: `
    <form [formGroup]="form"
          class="FotoItem image-input image-input-outline {{ template ? 'FotoItem--' + template : ''}}"
          [ngClass]="{'FotoItem-deleted': form.value.deleted}">
      <div (click)="onImageClick.emit(item)" *ngIf="edit" [ngStyle]="{height: this.height + 'px'}"
           class="FotoItem_Wrapper image-input-wrapper" [style.backgroundImage]="getImage()"></div>
      <a href="{{ item.fullUrl }}" target="_blank" *ngIf="!edit" class="FotoItem_Wrapper image-input-wrapper" [style.backgroundImage]="'url(' + (item.base64 ? item.base64 : item.fullUrl) +')'"></a>
      <div class="input-group input-group-sm" *ngIf="hasDescription">
        <input *ngIf="edit" formControlName="description" type="text" class="form-control" placeholder="Inserisci una descrizione..." (blur)="handleSaveInput()">
        <span *ngIf="!edit && form.value.description">{{ item.description }}</span>
      </div>
      <div *ngIf="form.value.deleted || form.value.moved" class="FotoItem_Deleted">
        <div *ngIf="form.value.deleted">
          L'immagine verrà eliminata<br>
          <button (click)="handleRipristina()" type="button" class="btn btn-sm btn-success mt-2">Ripristina
          </button>
        </div>
        <div *ngIf="form.value.moved">
          L'immagine sarà spostata in questa cartella:
          <select formControlName="cartella_id" class="form-control" (change)="handleSaveInput()">
            <option value="">Seleziona cartella</option>
            <option *ngFor="let cartella of cartelle" [value]="cartella.id" [disabled]="cartella.id === item.cartella_id">{{ cartella.nome }}</option>
          </select>
          <button (click)="handleRipristina()" type="button" class="btn btn-sm btn-success mt-2">Annulla</button>
        </div>
      </div>

      <div class="FotoItem_Buttons" *ngIf="edit && !form.value.deleted && !form.value.moved">
        <button *ngIf="canMove" (click)="handleMove()" class="btn btn-xs btn-primary mr-2">Sposta</button>
        <button (click)="handleDelete()" class="btn btn-xs btn-danger">Elimina</button>
      </div>
    </form>

  `
})

export class FotoItemComponent implements OnInit, OnChanges {

  form: FormGroup;

  description = null;
  @Input() edit = true;
  @Input() canMove = false;
  @Input() item = null;
  @Input() height = 200;
  @Input() template = '';
  @Input() hasDescription = true;
  @Input() cartelle = [];

  @Output() onUpdate = new EventEmitter();
  @Output() onImageClick = new EventEmitter();

  constructor(private sanitization: DomSanitizer, fb: FormBuilder) {

    this.form = fb.group({
      id: fb.control(null),
      description: fb.control(''),
      deleted: fb.control(false),
      moved: fb.control(false),
      cartella_id: fb.control(null),
    });

  }

  ngOnInit() {

    this.form.patchValue(this.item);

  }

  ngOnChanges({ item }: SimpleChanges) {

    if (item && item.currentValue) {
      this.form.patchValue(item.currentValue);
    }

  }

  handleDelete() {

    this.onUpdate.emit({...this.item, ...this.form.value,
      deleted: true
    });

  }

  handleRipristina() {

    this.onUpdate.emit({...this.item, ...this.form.value,
      deleted: false,
      moved: false,
      cartella_id: this.item.cartella_id
    });

  }

  handleMove() {

    this.onUpdate.emit({...this.item, ...this.form.value,
      moved: true
    });

  }

  getImage() {

    return this.item.base64 ? this.sanitization.bypassSecurityTrustStyle(`url(${this.item.base64})`) : `url(${this.item.fullUrl})`;

  }

  handleSaveInput() {

    this.onUpdate.emit({...this.item, ...this.form.value});

  }
}

