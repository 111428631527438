import { ApiClient } from '../../../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'clienti';
    }
    all(clienteId) {
        return this.http.get(`clienti/${clienteId}/checkup`);
    }
    create(clienteId, data) {
        return this.http.post(`clienti/${clienteId}/checkup`, data);
    }
    item(clienteId, id) {
        return this.http.get(`clienti/${clienteId}/checkup/${id}`);
    }
    update(clienteId, id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`clienti/${clienteId}/checkup/${id}`, data);
    }
    updateFoto(clienteId, id, data) {
        return this.http.post(`clienti/${clienteId}/checkup/${id}/foto`, data);
    }
    delete(clienteId, id) {
        return this.http.delete(`clienti/${clienteId}/checkup/${id}`);
    }
    pdf(clienteId, id, hideName = false) {
        return this.http.getEndpoint(`clienti/${clienteId}/checkup/${id}/pdf`, { hideName });
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
