import { Action, createReducer, on } from '@ngrx/store';
import { loadRecordsSuccess } from './actions';
import { FeatureState } from './feature.module';
import {logoutSuccess} from '../auth/actions';

const initialState: FeatureState = {
    categorieTrattamento: null,
    tipologieTrattamento: null
};

const featureReduce = createReducer(
    initialState,
    on(loadRecordsSuccess, (state, {type, ...settings}) => settings),
    on(logoutSuccess, () => initialState),
);

export function reducer (state: FeatureState | null, action?: Action) {
    return featureReduce(state, action);
}
