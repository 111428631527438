import { ApiClient } from '../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'assistenza';
    }
    all() {
        return this.http.get(`${this.apiSegment}`);
    }
    archived() {
        return this.http.get(`${this.apiSegment}/archived`);
    }
    create(data) {
        return this.http.post(`${this.apiSegment}`, data);
    }
    item(id) {
        return this.http.get(`${this.apiSegment}/${id}`);
    }
    itemCheckup(id) {
        return this.http.get(`${this.apiSegment}/${id}/checkup`);
    }
    update(id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`${this.apiSegment}/${id}`, data);
    }
    delete(id) {
        return this.http.delete(`${this.apiSegment}/${id}`);
    }
    detail(id) {
        return this.http.get(`${this.apiSegment}/${id}`);
    }
    messages(id) {
        return this.http.get(`${this.apiSegment}/${id}/messages`);
    }
    reply(id, data) {
        return this.http.post(`${this.apiSegment}/${id}/messages/reply`, data);
    }
    categories() {
        return this.http.get(`${this.apiSegment}/categories`);
    }
    restore(id) {
        return this.http.post(`${this.apiSegment}/${id}/restore`, {});
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
