import { Injectable} from '@angular/core';
import { ApiClient } from '../../core/services/api.service';

@Injectable({
    providedIn: 'root',
})

export class FeatureService {

    private apiSegment = 'prodotti';

    constructor(
        private http: ApiClient
    ) {
    }

  /**
   * Elenco prodotti
   *
   * @param onlyADomiclio boolean Solo prodotti a domicilio
   * @param categoryId null | int Id categoria filtro
   */

  all(filtro: string | null = null, categoryId = null) {

      let params = {}
      if (categoryId) {
        params = { ...params, category_id: categoryId }
      }
      if (filtro) {
        params = {...params, filtro }
      }

      return this.http.get(`${this.apiSegment}?` + this.http.getQueryStringFromObject(params));

    }

    create(data) {

        return this.http.post(`${this.apiSegment}`, data);

    }

    item(id) {

        return this.http.get(`${this.apiSegment}/${id}`);

    }

    update(id, data) {

        if(data instanceof FormData) {
            data.append('_method', 'PUT');
        } else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }

        return this.http.post(`${this.apiSegment}/${id}`, data);

    }

    delete(id) {

        return this.http.delete(`${this.apiSegment}/${id}`);

    }

    categories() {

        return this.http.get(`categorie-prodotti`)

    }

}
