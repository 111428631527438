import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, tap} from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Token } from '../../../models/token';
import * as authActions from './actions';
import * as accountActions from '../account/actions';
import * as routerActions from '../router/actions';

@Injectable()
export class Effects {

    initEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            switchMap(
                ({email, password}) => this.service.getToken()
                    .pipe(
                        filter(token => !!token),
                        map((token: Token) => authActions.loginSuccess({ token, redirect: false })),
                        catchError(({error}) => {
                            return of(authActions.loginFail({error}));
                        })
                    )
            )
            // mapTo(this.service.getToken()),
            // filter(token => !!token),
            // switchMap((token) => [
            //     authActions.saveAuth({ token }),
            //     accountActions.getAccount()
            // ]),
            // catchError((e) => {
            //     return of(authActions.logout());
            // })
        )
    );

    loginEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.login),
            switchMap(
                ({email, password}) => this.service.login(email, password)
                    .pipe(
                        map((token: Token) => authActions.loginSuccess({ token, redirect: true })),
                        catchError(({error}) => {
                            return of(authActions.loginFail({error}));
                        })
                    )
            )
        )
    );

    loginSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.loginSuccess),
            tap(({ token, redirect }) => this.service.saveToken(token)),
            switchMap(({ redirect }) => {
              if (redirect) {
                return [
                  accountActions.getAccount(),
                  routerActions.go({path: '/'})
                ];
              } else {
                return [accountActions.getAccount()];
              }
            })
        )
    );

    logoutEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.logout),
            tap(() => this.service.cleanAuth()),
            switchMapTo([
                authActions.logoutSuccess(),
                routerActions.go({path: '/auth/login'}),
            ])
        )
    );

    constructor(
        private actions$: Actions,
        private service: AuthService,
    ) {}

}
