import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

interface Column {
    label: string;
    name?: string;
    width?: number;
    align?: string;
    sort?: boolean;
    sortBy?: string;
    renderPipe?: string;
    render?(params?): any;
    hide?(record): boolean;
    hideColumnNameMobile?: boolean;
}

interface Action {
    isLink?: boolean;
    externalLink?: boolean;
    label?: string;
    url?(): any;
    render?(record): any;
    click?(): any;
    hide?(record): boolean;
}

export interface Config {
    actionsDropdown: Action[];
    actions: Action[];
    columns: Column[];
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-data-table',
    styles: [`
      @media (min-width: 1024px) {
          .table-mobile {
            display: none;
          }
      }
      @media (max-width: 1023px) {
          .table-desktop {
            display: none;
          }
      }
        .table th,
        .table td {
          font-size: 16px;
        }
      .table-mobile > div:not(:last-child) {
        border-bottom: 1px solid #ECF0F3;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      .table-mobile-buttons {
        margin-top: 10px;
      }
      .table-mobile-buttons a,
      .table-mobile-buttons button {
        width: 100%;
        margin-bottom: 10px;
      }
      .table-mobile .ml-3 {
        margin-left: 0 !important;
      }
      .table-mobile .btn-group {
        width: 100%;
      }
      .table-mobile .btn-group ul {
        width: 100%;
        text-align: center;
      }
    `],
    template: `
      <div [ngClass]="containerClasses">
        <div style="margin-left: -10px; margin-right: -10px">
            <div [ngClass]="{'table-responsive': false}">
                <table class="table table-hover table-desktop">
                <thead>
                <tr>
                    <ng-container *ngFor="let column of config.columns">
                      <ng-container>
                        <th
                            width="{{ column.width }}"
                            *ngIf="!column.hide"
                            [ngClass]="{'text-right': column.align === 'right', 'text-center': column.align === 'center'}"
                        >
                            {{ column.label }}
                            <button *ngIf="column.sort" (click)="handleSort(column.sortBy ? column.sortBy : column.name)" type="button" class="btn-clean"><i class="mdi" [ngClass]="{'mdi-arrow-up': order === 'desc', 'mdi-arrow-down': order === 'asc' }"></i></button>
                        </th>
                      </ng-container>
                    </ng-container>
                    <th *ngIf="config.actionsDropdown.length > 0 || config.actions.length > 0" width="150">&nbsp;</th>
                </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                <tr *ngFor="let record of (pagination ? (data | sort:order:orderBy).slice(currentPage * recordsPerPage, currentPage * recordsPerPage + recordsPerPage) : (data | sort:order:orderBy))" cdkDrag [cdkDragDisabled]="!sort">
                    <ng-container *ngFor="let column of config.columns">
                        <td *ngIf="!column.hide || !column.hide(record)" [ngClass]="{'text-right': column.align === 'right', 'text-center': column.align === 'center'}">
                            <div *ngIf="column?.renderPipe === 'date'">{{ record[column.name] | date: 'dd/MM/yyyy' }}</div>
                            <div *ngIf="!column?.renderPipe" [innerHTML]="this._sanitizer.bypassSecurityTrustHtml(column.render ? column.render(record) : record[column.name])"></div>
                        </td>
                    </ng-container>
                    <td *ngIf="config.actionsDropdown.length > 0 || config.actions.length > 0" style="white-space: nowrap; text-align: right;">
                      <div class="d-flex align-items-center">
                        <div *ngIf="config.actions && config.actions.length">
                            <div *ngFor="let action of config.actions">
                                <a *ngIf="(action.url !== undefined || action.isLink) && !action.externalLink && (!action.hide || !action.hide(record))"
                                   routerLink="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.classes"
                                   class="btn btn-sm btn-default" [innerHTML]="action.label ? action.label : action.render(record)"></a>
                                <a target="_blank"
                                   *ngIf="(action.url !== undefined || action.isLink) && action.externalLink && (!action.hide || !action.hide(record))"
                                   href="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.classes"
                                   class="btn btn-default btn-sm" [innerHTML]="action.label ? action.label : action.render(record)"></a>
                                <button *ngIf="(action.url === undefined && !action.isLink) && (!action.hide || !action.hide(record))"
                                        class="btn btn-default btn-sm" [ngClass]="action.classes"
                                        (click)="action.click(record, $event)" [innerHTML]="action.label ? action.label : action.render(record)"></button>
                            </div>
                        </div>
                        <app-actions-dropdown class="ml-3"
                                              *ngIf="getDropdownActions(record)"
                                              [record]="record"
                                              [actions]="config.actionsDropdown"></app-actions-dropdown>
                      </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="table-mobile" style="margin-left: 10px; margin-right: 10px">
              <div *ngFor="let record of (pagination ? (data | sort:order:orderBy).slice(currentPage * recordsPerPage, currentPage * recordsPerPage + recordsPerPage) : (data | sort:order:orderBy))">
                <div *ngFor="let column of config.columns" class="pb-1">
                  <span *ngIf="!column?.hideColumnNameMobile" class="mr-2">{{ column.label }}:</span>
                  <ng-container *ngIf="!column.hide || !column.hide(record)">
                      <span *ngIf="column?.renderPipe === 'date'">{{ record[column.name] | date: 'dd/MM/yyyy' }}</span>
                      <span *ngIf="!column?.renderPipe" [innerHTML]="this._sanitizer.bypassSecurityTrustHtml(column.render ? column.render(record) : record[column.name])"></span>
                  </ng-container>
                </div>
                <div>
                  <div class="table-mobile-buttons" *ngIf="config.actions && config.actions.length">
                    <ng-container *ngFor="let action of config.actions">
                      <a *ngIf="(action.url !== undefined || action.isLink) && !action.externalLink && (!action.hide || !action.hide(record))"
                         routerLink="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.classes"
                         class="btn btn-sm btn-default">{{ action.label ? action.label : action.render(record) }}</a>
                      <a target="_blank"
                         *ngIf="(action.url !== undefined || action.isLink) && action.externalLink && (!action.hide || !action.hide(record))"
                         href="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.classes"
                         class="btn btn-default btn-sm">{{ action.label ? action.label : action.render(record) }}</a>
                      <button *ngIf="(action.url === undefined && !action.isLink) && (!action.hide || !action.hide(record))"
                              class="btn btn-default btn-sm" [ngClass]="action.classes"
                              (click)="action.click(record, $event)">{{ action.label ? action.label : action.render(record) }}</button>
                    </ng-container>
                  </div>
                  <app-actions-dropdown class="ml-3"
                                        *ngIf="getDropdownActions(record)"
                                        [record]="record"
                                        [actions]="config.actionsDropdown"></app-actions-dropdown>
              </div>
            </div>
        </div>
        <app-pagination *ngIf="pagination" [count]="data.length" [current]="currentPage" [perPage]="recordsPerPage"
                        (changePage)="handleChangePage($event)"></app-pagination>
      </div>
    `
})

export class DataTableComponent {

    @Input() orderBy = null;
    @Input() order = 'asc';
    @Input() responsive: boolean = true;
    @Input() containerClasses = '';
    @Input() sort: boolean = false
    currentPage = 0;
    @Input() recordsPerPage:number = 10;

    @Input() data = [];
    @Input() config: Config = {
        columns: [],
        actions: [],
        actionsDropdown: []
    };
    @Input() pagination = false;
    @Output() onSort = new EventEmitter();

    constructor(
        private _sanitizer: DomSanitizer
    ) {
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    handleChangePage(page) {
        this.currentPage = page;
    }

    handleChangePagination(pagination) {
        this.recordsPerPage = pagination;
    }

    handleSort(column) {

        this.orderBy = column;
        this.order = this.order === 'asc' ? 'desc' : 'asc';

    }

    getDropdownActions(record) {

        return this.config.actionsDropdown && this.config.actionsDropdown.length && this.config.actionsDropdown.filter(item => (item.hide ? !item.hide(record) : true)).length

    }

  drop($event) {

    let ids = this.data.map(({ id }) => id);
    ids = this.moveItemInArray(ids, $event.previousIndex, $event.currentIndex)

    let records: any[] = [];
    // @ts-ignore
    records = ids.map(id => this.data.find((item) => item.id === id));
    this.data = records;

    this.onSort.emit(records.map(({ id }) => id));

  }

  moveItemInArray(array: number[], fromIndex: number, toIndex: number): number[] {

    function clamp(value: number, max: number): number {
      return Math.max(0, Math.min(max, value));
    }

    const from = clamp(fromIndex, array.length - 1);
    const to = clamp(toIndex, array.length - 1);

    if (from === to) {
      return array;
    }

    const target = array[from];
    const delta = to < from ? -1 : 1;

    for (let i = from; i !== to; i += delta) {
      array[i] = array[i + delta];
    }

    array[to] = target;
    return array;


  }

}
