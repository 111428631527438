import { Component } from '@angular/core';

@Component({
  template: `
    - cliente<br>
    - nome cartella
  `
})

export class NuovaCartellaComponent {}
