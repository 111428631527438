import { StoreModule } from '@ngrx/store';

import { reducer as reducers} from './reducer';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FeatureEffects } from './effects';
import { CategoriaTrattamento } from '../../../models/CategoriaTrattamento';
import { TipologiaTrattamento } from '../../../models/TipologiaTrattamento';

export interface FeatureState {
    categorieTrattamento: CategoriaTrattamento[],
    tipologieTrattamento: TipologiaTrattamento[]
}

@NgModule({
    imports: [
        StoreModule.forFeature('settings', reducers),
        EffectsModule.forFeature([FeatureEffects])
    ]
})

export class FeatureModule {
}


