import { Action, createReducer, on } from '@ngrx/store';
import * as uiActions from './actions';
import { State } from './index';
import {logoutSuccess} from '../auth/actions';

const initialState: State = {
    showLoading: false,
    isMenuHidden: true
};

export const uiReducer = createReducer(
    initialState,
    on(uiActions.toggleMenu, (state, action) => ({...state, isMenuHidden: action.value})),
    on(logoutSuccess, () => initialState),
);

export function reducer (state: State | null, action?: Action) {
    return uiReducer(state, action);
}
