import { Action, createReducer, on } from '@ngrx/store';
import {
  addRecordSuccess,
  deleteRecordSuccess,
  loadRecordsSuccess,
  updateConfigSuccess,
  updateRecordSuccess
} from './actions';
import { Model } from '../../models';
import {logoutSuccess} from '../../../../../core/store/auth/actions';

const initialState: Model[] = null;

const featureReduce = createReducer(
    initialState,
    on(loadRecordsSuccess, (state, {records}) => records),
    on(addRecordSuccess, (state, {record}) => [record, ...state]),
    on(updateRecordSuccess, (state, {record}) => state.map(item => Number(item.id) === Number(record.id) ? {...item, ...record} : item)),
    on(deleteRecordSuccess, (state, {id}) => state.filter(item => item.id !== id)),
    on(logoutSuccess, () => initialState),
);

export function reducer (state: Model[] | null, action?: Action) {
    return featureReduce(state, action);
}
