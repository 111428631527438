import { Injectable} from '@angular/core';
import { ApiClient } from '../../core/services/api.service';

@Injectable({
    providedIn: "root",
})

export class FeatureService {

    private apiSegment = 'assistenza';

    constructor(
        private http: ApiClient
    ) {
    }

    all() {

        return this.http.get(`${this.apiSegment}`);

    }

    archived() {

        return this.http.get(`${this.apiSegment}/archived`);

    }

    create(data) {

        return this.http.post(`${this.apiSegment}`, data);

    }

    item(id) {

        return this.http.get(`${this.apiSegment}/${id}`);

    }

    itemCheckup(id) {

        return this.http.get(`${this.apiSegment}/${id}/checkup`);

    }

    update(id, data) {

        if(data instanceof FormData) {
            data.append('_method', 'PUT');
        } else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }

        return this.http.post(`${this.apiSegment}/${id}`, data);

    }

    delete(id) {

        return this.http.delete(`${this.apiSegment}/${id}`);

    }

    detail(id) {

        return this.http.get(`${this.apiSegment}/${id}`);

    }

    messages(id) {

        return this.http.get(`${this.apiSegment}/${id}/messages`);

    }

    reply(id, data) {

        return this.http.post(`${this.apiSegment}/${id}/messages/reply`, data);

    }

    categories() {

        return this.http.get(`${this.apiSegment}/categories`);

    }

  restore(id) {

    return this.http.post(`${this.apiSegment}/${id}/restore`, {});

  }


}
