import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durata',
  pure: false
})

export class DurataPipe implements PipeTransform {
  transform(value: any): any {
    value = parseFloat(value);
    if (value === 0.5)
      return '30 minuti';
    else if (value == 1)
      return '1 ora';
    else if (value === 1.5)
      return `1 ora e mezza`;
    else if (value > 1.5)
      return `${value} ore`;
  }
}
