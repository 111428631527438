import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { hasCustomProductsPermission } from '../../core/store/account/selectors';
import { Store } from '@ngrx/store';
import { Prodotto } from '../../models/Prodotto';


@Component({
  selector: 'app-products-selector',
  template: `
    <ng-container  *ngIf="!(hasCustomProductsPermission$ | async) else customProducts">
      <table width="100%" *ngIf="canEdit">
        <tr>
          <td class="pt-0" style="border-top: 0">
            <ng-select
              [items]="productsList"
              [(ngModel)]="selectedProduct"
              bindLabel="name"
              class="select-product"
              [multiple]="false"
              [closeOnSelect]="true"
              [searchFn]="customSearchFn"
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="CategoryBox">
                  <div class="CategoryBox_Color" [style.background]="item.category?.color"></div>
                  <div class="CategoryBox_Text">{{item.name}}</div>
                </div>
              </ng-template>
            </ng-select>
          </td>
          <td width="60" class="text-right pt-0" style="border-top: 0">
            <button type="button" (click)="handleAddProdotto()" class="btn btn-default"><span class="flaticon2-add"></span></button>
          </td>
        </tr>
      </table>
    </ng-container>
    <ng-template #customProducts>
        <app-linee-prodotto-selector [filtro]="filtro" [vendita]="vendita" (addProducts)="handleAddProducts($event)"></app-linee-prodotto-selector>
    </ng-template>


    <table width="100%" cdkDropListGroup class="table table-vertical-center">
      <tbody cdkDropList [cdkDropListData]="selectedProducts" (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let product of selectedProducts; let i = index" cdkDrag style="cursor: move">
            <td class="pt-2">
              {{product.name}}
              <input *ngIf="note" type="text" class="form-control" placeholder="Note..." (change)="handleUpdate($event, i)" [value]="product.note ? product.note : ''">
            </td>
            <td class="pt-2 text-right" *ngIf="canEdit">
          <button type="button" (click)="handleRemoveProduct(i)"  class="btn btn-danger btn-sm"><span class="flaticon2-trash"></span></button>
        </td>
        </tr>
      </tbody>
    </table>
  `
})

export class ProductsSelectorComponent {

  @Input() canEdit = true;
  @Input() productsList = [];
  @Input() selectedProducts = [];
  @Input() note = false;
  @Input() vendita = false;
  @Input() filtro: 'ordine' | 'trattamento' | null = null;
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateNote: EventEmitter<any> = new EventEmitter<any>();
  hasCustomProductsPermission$ = this.store.select(hasCustomProductsPermission);

  selectedProduct = null;

  constructor(private store: Store) {

  }

  handleAddProdotto() {
    this.onAdd.emit(this.selectedProduct);
    this.selectedProduct = null;
  }

  handleRemoveProduct(index) {
    this.onRemove.emit(index);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || (item.category && item.category.name.toLocaleLowerCase().indexOf(term) > -1);
  }

  handleUpdate($event, id) {

    this.onUpdateNote.emit({ id, value: $event.currentTarget.value });

  }

  handleAddProducts(products: Prodotto[]) {

    products.map(record => this.onAdd.emit(record));

  }
}
