import { Injectable} from '@angular/core';
import { ApiClient } from '../../services/api.service';

@Injectable({
    providedIn: 'root',
})

export class ApiService {

    public apiSegment = '[segment]';

    constructor(
        public http: ApiClient
    ) {
    }

    all() {

      console.log('apiSegment', this.apiSegment);

        return this.http.get(`${this.apiSegment}`);

    }

    create(data) {

        return this.http.post(`${this.apiSegment}`, data);

    }

    item(id) {

        return this.http.get(`${this.apiSegment}/${id}`);

    }

    itemCheckup(id) {

        return this.http.get(`${this.apiSegment}/${id}/checkup`);

    }

    update(id, data) {

        if(data instanceof FormData) {
            data.append('_method', 'PUT');
        } else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }

        return this.http.post(`${this.apiSegment}/${id}`, data);

    }

    delete(id) {

        return this.http.delete(`${this.apiSegment}/${id}`);

    }

    getQueryString(parameters = {}, hideEmpty = true) {

      return Object.keys(parameters).filter(key => parameters[key]).map(key => `${key}=${parameters[key]}`).join('&')

    }

  updateSort(ids: any[]) {

    return this.http.post(`${this.apiSegment}/sort`, {
      _method: 'PUT',
      ids
    });

  }

}
