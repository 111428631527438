import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-scala-alopecia',
    styles: [`
        .scala {
            position: relative;
            display: inline-block;
            overflow: hidden;
            margin-bottom: 30px;
        }

        .scala img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
        }
        
        .scala-items {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        
        .scala-items > * {
            width: calc(100% / 3);
            height: calc(100% / 4);
            opacity: 0.6;
            background: transparent;
            border: 2px solid #ECF0F3;
            outline: none;
        }
        .scala-items.no-hover > * {
            cursor: default;
        }
        .scala-items:not(.no-hover) > *:hover,
        .scala-items > .selected {
            border: 2px solid #1BC5BD;
        }
        .scala-items > .selected {
            background: rgba(27, 197, 189, 0.2);
        }

        .scala-ludwing .scala-items > * {
            height: calc(100% / 3);
        }

    `],
    template: `
        <div class="scala" [ngClass]="{'no-hover': onlyShow, 'scala-ludwing': scala !== 'Maschio'}">
            <img src="/assets/scala-{{ scala === 'Maschio' ? 'namilton' : 'ludwing'}}.jpg" alt="">
            <div class="scala-items" [ngClass]="{'no-hover': onlyShow}">
                <button (click)="onClick.emit(1)" type="button" [ngClass]="{'selected': selected === 1}" data-id="1"></button>
                <button (click)="onClick.emit(2)" type="button" [ngClass]="{'selected': selected === 2}" data-id="2"></button>
                <button (click)="onClick.emit(3)" type="button" [ngClass]="{'selected': selected === 3}" data-id="3"></button>
                <button (click)="onClick.emit(4)" type="button" [ngClass]="{'selected': selected === 4}" data-id="4"></button>
                <button (click)="onClick.emit(5)" type="button" [ngClass]="{'selected': selected === 5}" data-id="5"></button>
                <button (click)="onClick.emit(6)" type="button" [ngClass]="{'selected': selected === 6}" data-id="6"></button>
                <button (click)="onClick.emit(7)" type="button" [ngClass]="{'selected': selected === 7}" data-id="7"></button>
                <button (click)="onClick.emit(8)" type="button" [ngClass]="{'selected': selected === 8}" data-id="8"></button>
                <button (click)="onClick.emit(9)" type="button" [ngClass]="{'selected': selected === 9}" data-id="9"></button>
                <button (click)="onClick.emit(10)" type="button" [ngClass]="{'selected': selected === 10}" data-id="10"></button>
                <button (click)="onClick.emit(11)" type="button" [ngClass]="{'selected': selected === 11}" data-id="11"></button>
                <button (click)="onClick.emit(12)" type="button" [ngClass]="{'selected': selected === 12}" data-id="12"></button>
            </div>
        </div>

    `
})

export class ScalaAlopeciaComponent {

    @Input() scala = 'Maschio';
    @Input() onlyShow = false;
    @Input() selected = null;
    @Output() onClick = new EventEmitter();

}