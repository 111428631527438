import { Injectable } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    loadRecords,
    loadRecordsSuccess
} from './actions';
import {
    filter,
    map,
    switchMap, withLatestFrom
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getCategorieTrattamento } from './selectors';

@Injectable()
export class FeatureEffects {

    loadRecords$ = createEffect(() => this.actions$.pipe(
        ofType(loadRecords),
        withLatestFrom(this.store.select(getCategorieTrattamento)),
        filter(([action, state]) => state === null),
        switchMap(() => this.service.settings()
            .pipe(
                map(({tipologieTrattamento, categorieTrattamento}: any) => loadRecordsSuccess({ tipologieTrattamento: tipologieTrattamento, categorieTrattamento })),
            )
        )
    ))

    constructor(
        private service: AccountService,
        private store: Store,
        private actions$: Actions
    ){}

}