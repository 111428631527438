import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FeatureService } from '../../features/dashboard/feature.service';
import { convertDT, dateString, timeString } from '../helpers';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DateFormatPipe as DatePipe } from '../pipes/dateformat.pipe';
import { CalendarDateFormatter, CalendarEventTitleFormatter } from 'angular-calendar';
import { CustomDateFormatterProvider } from '../providers/custom-date-formatter.provider';
import { CustomEventTitleFormatterProvider } from '../providers/custom-event-title-formatter.provider';

@Component({
  providers: [DatePipe, {
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatterProvider,
  }, {
    provide: CalendarEventTitleFormatter,
    useClass: CustomEventTitleFormatterProvider,
  }],
  styles: [`
    h3 {
      margin-bottom: 20px;
      margin-top: 5px;
    }
    .wrapper {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .wrapper_form {
      width: 500px;
      margin-left: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .wrapper_calendar {
      flex: 1;
      overflow: scroll;
    }
  `],
  template: `

    <div class="wrapper">
      <div class="wrapper_calendar">
        <h3>{{ (viewDate$ | async | dateFormat: "EEEE dd MMMM yyyy") | titlecase }}</h3>

        <mwl-calendar-day-view
          [viewDate]="viewDate$ | async"
          [events]="events"
          [dayStartHour]="8"
          [dayEndHour]="20"
          [hourSegmentHeight]="80"
          [hourSegments]="1"
          [tooltipAppendToBody]="false"
          (hourSegmentClicked)="handleSegmentClick($event)"
        >
        </mwl-calendar-day-view>
      </div>
      <div class="wrapper_form">
        <div class="modal-header">
          <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
            <h4 class="modal-title"><strong>Data appuntamento</strong></h4>
          </div>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (submit)="handleSubmit()">
            <p>Seleziona la data dell'appuntamento:</p>
            <app-datetime-picker formControlName="data"></app-datetime-picker>

            <div *ngIf="note" class="mt-3 mb-3">Note: {{ note }}</div>

            <div *ngIf="pianificaFuturi">
              <label class="checkbox checkbox-success mt-5">
                <input type="checkbox" formControlName="calcola">
                <span></span>Calcola automaticamente le date dei prossimi appuntamenti.<br />
                <small style="display: block; margin-top: 5px;">Ricorda che il calcolo potrebbe coincidere con giorni di chiusura non ancora programmati o festività. Controllale sempre prima di confermarle al cliente.</small>
              </label>
            </div>

            <div *ngIf="showConfirm">
              <label class="checkbox checkbox-success mt-5">
                <input type="checkbox" formControlName="confermato" value="1">
                <span></span>L'appuntamento <i class="text-success" style="font-style: normal">è già stato confermato</i>. Non serve inviare l'sms di richiesta conferma, basta quello di promemoria<br />
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
          <button [disabled]="form.invalid" class="btn btn-primary" (click)="handleSubmit()">Conferma</button>
        </div>
      </div>
    </div>

  `
})

export class SelectDateModalComponent {

  public pianificaFuturi:boolean = false;
  public note: string = '';
  public showConfirm: boolean = false;

  form: FormGroup;
  viewDate$ = new BehaviorSubject(new Date())
  events = [];

  constructor(private service: FeatureService, public modal: NgbActiveModal, private fb: FormBuilder) {

    this.form = this.fb.group({
      data: fb.control(null),
      calcola: fb.control(false),
      confermato: fb.control(true),
    })

    this.form.valueChanges.subscribe(({ data }) => {
      console.log('data => ', data);
      this.viewDate$.next(new Date(data))
    })

    this.viewDate$.pipe(
      debounceTime(500),
      switchMap((date) => this.service.calendar({ date: dateString(date), type: 'day' })
        .pipe(
          map((events: any[]) => {

            this.events = events.map(item => ({
              id: item.id,
              draggable: false,
              allDay: item.type === 'chiusura',
              title: `${item.description}`,
              start: new Date(convertDT(item.data)),
              end: item.type === 'chiusura' ? null : new Date(convertDT(item.dataFine)),
              cssClass: `event-${item.type} event-stato-${item.stato}`,
              meta: {
                cliente: item.cliente,
                descrizione: item.description,
                note: item.note,
                stato: item.stato,
                durata: item.durata,
              }
            }))

          })
        )
      )
    ).subscribe((response) => {})

  }

  public setData(data: string) {

    this.form.patchValue({
      data
    })

  }

  handleChange(date: Moment) {

    // if (date) {
    //   this.data = date.format('YYYY-MM-DD')
    // }

  }

  handleSubmit() {

    this.modal.close(this.form.value)

  }

  handleSegmentClick($event: { date: Date; sourceEvent: MouseEvent }) {

    this.form.patchValue({
      data: `${dateString($event.date)} ${timeString($event.date)}:00`
    })

  }
}
