import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'clienti', loadChildren: () => import('./features/clienti/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'assistenza', loadChildren: () => import('./features/assistenza/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'preventivi', loadChildren: () => import('./features/preventivi/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'risorse', loadChildren: () => import('./features/risorse/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'academy-events', loadChildren: () => import('./features/academy-events/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'prodotti', loadChildren: () => import('./features/prodotti/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'faq', loadChildren: () => import('./features/faq/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'documenti', loadChildren: () => import('./features/documenti/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'notifications', loadChildren: () => import('./features/notifications/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },
  { path: 'statistiche', loadChildren: () => import('./features/statistiche/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },

  { path: 'admin', loadChildren: () => import('./features/admin/feature.module').then(m => m.FeatureModule), canActivate: [AuthGuard] },

  { path: 'account', loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
  { path: 'messaggistica', loadChildren: () => import('./features/messaggistica/feature.module').then(m => m.FeatureModule) },
  { path: '**', redirectTo: 'search' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
