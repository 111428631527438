import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-switch',
    template: `
        <span class="switch">
            <label class="m-0">
                <input [disabled]="disabled" (ngModelChange)="onChange.emit(!value)" [(ngModel)]="value" type="checkbox" [checked]="value">
                <span></span>
            </label>
        </span>
    `
})

export class SwitchComponent {

    @Input('value') value: boolean;
    @Input('disabled') disabled: boolean = false;
    @Output('onChange') onChange = new EventEmitter();

}
