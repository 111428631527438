

    <div class="modal-header">
      <h4 class="modal-title">Preventivo</h4>
    </div>
    <div class="modal-body">

      <app-laravel-errors [errors]="errors$ | async"></app-laravel-errors>

    <form [formGroup]="form">

      <div class="form-group">
        <div><label><input type="radio" formControlName="in_rubrica" [value]="true" class="checkbox pr-1"/> Cliente
          presente in rubrica</label></div>
        <div><label><input type="radio" formControlName="in_rubrica" [value]="false" class="checkbox pr-1"/> Cliente non in
          rubrica</label></div>
      </div>

      <ng-container *ngIf="form.value.in_rubrica !== null">

        <ng-container *ngIf="form.value.in_rubrica === 0">

          <div class="form-group row">
            <label class="col-form-label">Nome e cognome</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="nome_cliente"
                     [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.nome_cliente }" required>
              <span *ngIf="errors && errors.errors && errors.errors.nome_cliente" class="invalid-feedback"
                    role="alert">{{ errors.errors.nome_cliente[0] }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label">Telefono</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="telefono"
                     [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.telefono }" required>
              <span *ngIf="errors && errors.errors && errors.errors.telefono" class="invalid-feedback"
                    role="alert">{{ errors.errors.telefono[0] }}</span>
            </div>
          </div>

        </ng-container>
        <ng-container *ngIf="form.value.in_rubrica === true">

          <div class="form-group row">
            <label class="col-form-label">Cliente</label>
            <div class="control-input">
              <ng-select
                [placeholder]="'Seleziona cliente'"
                [items]="clienti$ | async"
                formControlName="cliente_id"
                bindLabel="fullName"
                bindValue="id"
                [closeOnSelect]="true"
              ></ng-select>
              <span *ngIf="errors && errors.errors && errors.errors.cliente_id" class="invalid-feedback"
                    role="alert">{{ errors.errors.cliente_id[0] }}</span>
            </div>
          </div>

        </ng-container>

        <div class="form-group row">
          <label class="col-form-label">Note</label>
          <div class="control-input">
          <textarea class="form-control" type="text" formControlName="note"
                    [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.note }"></textarea>
            <span *ngIf="errors && errors.errors && errors.errors.note" class="invalid-feedback"
                  role="alert">{{ errors.errors.note[0] }}</span>
          </div>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th>Trattamento</th>
            <th>Q.tà</th>
            <th>Costo unit.</th>
            <th class="text-right" width="90">Totale</th>
          </tr>
          </thead>
          <tbody formArrayName="trattamento">
          <tr *ngFor="let _ of formTrattamento.controls; index as i">
            <ng-container [formGroupName]="i">
              <td>
                <div class="mt-3">{{ _.get('name').value }}</div>
              </td>
              <td><input class="form-control" type="number" placeholder="0" formControlName="qta"/></td>
              <td><input class="form-control"
                         [ngClass]="{'is-invalid': errors && errors.errors && errors.errors['trattamento.' + i] }"
                         type="number" step="0.01" placeholder="0,00" formControlName="costo"/></td>
              <td class="text-right">
                <div class="mt-3">{{ _.get('qta').value * _.get('costo').value | currency: '€' }}</div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>

        <table class="table">
          <thead>
          <tr>
            <th>Prodotto</th>
            <th class="text-center">Omaggio</th>
            <th class="text-right" width="130">Costo</th>
          </tr>
          </thead>
          <tbody formArrayName="prodotti">
          <tr *ngFor="let _ of formProdotti.controls; index as i">
            <ng-container [formGroupName]="i">
              <td>
                <div class="d-flex align-items-center mr-3">
                  <button (click)="handleDeleteProduct(i)" class="btn-danger btn btn-sm btn-icon mr-3"><i
                    class="flaticon2-trash"></i></button>
                  {{ _.get('name').value }}
                </div>
              </td>
              <td class="text-center">
                <input type="checkbox" style="margin-top: 15px" formControlName="omaggio">
              </td>
              <td>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">€</div>
                  </div>
                  <input type="number" step="0.01" class="form-control" style="width: 50px" formControlName="costo">
                </div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>

        <ng-select
          [items]="prodotti$ | async"
          bindLabel="name"
          [placeholder]="'Aggiungi prodotto'"
          (change)="handleAddProduct($event)"
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="CategoryBox">
              <div class="CategoryBox_Color" [style.background]="item.category?.color"></div>
              <div class="CategoryBox_Text">{{item.name}}</div>
            </div>
          </ng-template>
          <ng-template ng-label-tmp>
            Aggiungi prodotto
          </ng-template>
        </ng-select>

        <table class="table" style="margin-top: 30px">
          <thead>
          <tr>
            <th>Promozioni</th>
            <th class="text-right" width="130">Importo</th>
          </tr>
          </thead>
          <tbody formGroupName="promozioni">
          <tr>
            <td>Sconto trattamento</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_trattamento">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sconto prodotti</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_prodotti">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <!--
          <tr>
            <td>Omaggio prodotto</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="prodotto">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          -->
          <tr>
            <td>Omaggio ultimo trattamento</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="trattamento">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          </tbody>
        </table>

        <hr style="margin-top: 50px;">

        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale trattamento:</div>
          <div>{{ getTotaleTrattamento() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale prodotti:</div>
          <div>{{ getTotaleProdotti() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto trattamento:</div>
          <div>-{{ getScontoTrattamento() | currency: '€'}}</div>
        </div>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto prodotti:</div>
          <div>-{{ getScontoProdotti() | currency: '€'}}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale:</div>
          <div>{{ getTotaleProdotti() + getTotaleTrattamento() - getTotalePromo() | currency: '€'}}</div>
        </div>

      </ng-container>

    </form>

    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" (click)="modal.dismiss()" class="btn btn-light">Annulla</button>
      <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="handleSubmit()">Salva</button>
    </div>


  