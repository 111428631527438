import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {

    _isVisible = false;
    isVisible = new Subject<boolean>();

    show() {
        this._isVisible = true;
        this.isVisible.next(this._isVisible);
    }

    hide() {
        this._isVisible = false;
        this.isVisible.next(this._isVisible);
    }

    toggle() {
        this._isVisible = !this._isVisible;
        this.isVisible.next(this._isVisible);
    }
}