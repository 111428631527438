
        <div class="modal-header">
            <h4 class="modal-title">{{ record ? 'Modifica' : 'Nuovo' }} operatore</h4>
        </div>
        <div class="modal-body">

            <form [formGroup]="form" action="">

                <div *ngIf="errors && errors.message" class="alert alert-danger">{{ errors.message }}</div>
                <div class="form-group row">
                    <label class="col-form-label">Indirizzo email</label>
                    <div class="control-input">
                        <input class="form-control" type="email" formControlName="email"
                               name="email"
                               [ngClass]="{'is-invalid': errors?.email }">
                        <span *ngIf="errors?.email" class="invalid-feedback" role="alert">{{ errors?.email[0] }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Nome</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="nome"
                               [ngClass]="{'is-invalid': errors?.nome }"
                               required>
                        <span *ngIf="errors?.nome" class="invalid-feedback" role="alert">{{ errors?.nome[0] }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Cognome</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="cognome"
                               [ngClass]="{'is-invalid': errors?.cognome }"
                               required>
                        <span *ngIf="errors?.cognome"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.cognome[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Ragione sociale</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="company_name"
                               name="company_name"
                               [ngClass]="{'is-invalid': errors?.company_name }"
                               required>
                        <span *ngIf="errors?.company_name"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.company_name[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Codice fiscale</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="codice_fiscale"
                               name="codice_fiscale"
                               [ngClass]="{'is-invalid': errors?.codice_fiscale }"
                               required>
                        <span *ngIf="errors?.codice_fiscale"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.codice_fiscale[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Partita IVA</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="p_iva"
                               name="p_iva"
                               [ngClass]="{'is-invalid': errors?.p_iva }"
                               required>
                        <span *ngIf="errors?.p_iva"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.p_iva[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Indirizzo</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="address"
                               name="address"
                               [ngClass]="{'is-invalid': errors?.address }"
                               required>
                        <span *ngIf="errors?.address"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.address[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Comune</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="comune"
                               name="comune"
                               [ngClass]="{'is-invalid': errors?.comune }"
                               required>
                        <span *ngIf="errors?.comune"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.comune[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">CAP</label>
                    <div class="control-input">
                        <input class="form-control" type="number" formControlName="cap"
                               name="cap"
                               maxlength="5"
                               [ngClass]="{'is-invalid': errors?.cap }"
                               required>
                        <span *ngIf="errors?.cap"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.cap[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Provincia</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="provincia"
                               name="provincia"
                               maxlength="2"
                               [ngClass]="{'is-invalid': errors?.provincia }"
                               required>

                        <p class="form-text text-muted">Indicare la provincia con le due lettere (ad es. VR / VI /
                            PD...)</p>
                        <span *ngIf="errors?.provincia"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.provincia[0] }}
                </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Telefono</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="phone"
                               name="phone"
                               [ngClass]="{'is-invalid': errors?.phone }"
                               required>
                        <span *ngIf="errors?.phone"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.phone[0] }}
                </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <p>Compilare indirizzo PEC oppure codice SDI per la fatturazione degli sms.</p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label">PEC</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="pec"
                               name="pec"
                               [ngClass]="{'is-invalid': errors?.pec }">
                        <span *ngIf="errors?.pec"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.pec[0] }}
                </span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label">SDI</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="sdi"
                               name="sdi"
                               [ngClass]="{'is-invalid': errors?.sdi }">
                        <span *ngIf="errors?.sdi"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.sdi[0] }}
                </span>
                    </div>
                </div>
                <div class="form-group row" *ngIf="record">
                    <label class="col-form-label">Utente abilitato</label>
                    <div class="control-input">
                    <span class="switch">
                        <label>
                            <input name="enabled" formControlName="enabled" type="checkbox" checked="checked">
                            <span></span>
                        </label>
                    </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-form-label">Permessi</label>
                    <div class="control-input">
                        <div class="checkbox-list">
                            <div *ngFor="let permission of permissionsList; let i = index">
                                <label class="checkbox">
                                    <input [checked]="formPermissions.value.indexOf(permission.id) !== -1" (change)="permissionClickHandler($event)" type="checkbox" [value]="permission.id"> {{permission.value}}<span></span>
                                </label>
                            </div>
                        </div>

                        <span *ngIf="errors?.permissions"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.permissions[0] }}
                    </span>

                    </div>
                </div>

              <div class="form-group row">
                <label class="col-form-label">Note</label>
                <div class="control-input">
                  <textarea name="note" formControlName="note" class="form-control"></textarea>
                </div>
              </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
            <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
        </div>
    