import { NgModule } from '@angular/core';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material';
import { LoaderComponent } from './components/loader.component';
import { TopbarComponent } from './components/topbar.component';
import { MenuComponent } from './components/menu.component';
import { PermissionOldDirective } from './services/permission-old.directive';
import { AppStoreModule } from './store/store.module';

const COMPONENTS = [
  TopbarComponent,
  SidebarComponent,
  LoaderComponent,
  MenuComponent,
  PermissionOldDirective,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    AppStoreModule
  ]
})
export class CoreModule {
}
