import { ApiService } from '../../../../core/store/base/api-service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/api.service";
export class FeatureService extends ApiService {
    constructor() {
        super(...arguments);
        this.apiSegment = 'prodotti';
    }
    updateSort(ids) {
        return this.http.post(`${this.apiSegment}/sort`, {
            _method: 'PUT',
            ids
        });
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
