import { select, Store } from '@ngrx/store';
import { getToken } from '../store/auth/selectors';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { go } from '../store/router/actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AuthGuard {
    constructor(store) {
        this.store = store;
    }
    canActivate() {
        return this.store.pipe(select(getToken), map((token) => !!token), distinctUntilChanged(), tap(isLogged => {
            if (!isLogged) {
                this.store.dispatch(go({ path: '/auth/login' }));
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store)); }, token: AuthGuard, providedIn: "root" });
