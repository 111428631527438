import {createSelector} from '@ngrx/store';
import {AppState} from '../store.module';
import {State} from './index';

export const selectAuth = (state: AppState) => state.auth;

export const getToken = createSelector(
    selectAuth,
    (state: State) => state.token ? state.token : null
);

export const isLogged = createSelector(
    selectAuth,
    (state: State) => !!state.token
)

export const getError = createSelector(
    selectAuth,
    (state: State) => state.error
);
