import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from './webcam/domain/webcam-image';
import { loadRecords } from '../../app/features/clienti/store/list/actions';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getRecords } from '../../app/features/clienti/store/list/selectors';
import { FeatureService } from '../../app/features/clienti/subfeatures/foto/feature.service';
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-photo-booth',
  styleUrls: ['./photo-booth.scss'],
  template: `
    <div class="PhotoBoot">
      <div class="PhotoBootWrapper">
        <div class="PhotoBootControls">
          <select class="form-control" [(ngModel)]="selectedDeviceId">
            <option [ngValue]="null">Seleziona camera</option>
            <option *ngFor="let device of devices; let i = index"
                    [value]="device.deviceId">{{device.label !== '' ? device.label : 'Camera ' + i }}</option>
          </select>
          <button (click)="searchDevices()" class="btn btn-default pt-1 pb-0"><i class="mdi mdi-refresh p-0 m-0"></i>
          </button>
        </div>
        <div class="PhotoBootTimer">
          Timer (secondi):
          <input type="number" [value]="timer" (change)="timer = $event.target['value']"/>
        </div>
        <button (click)="triggerSnapshot()" [disabled]="!selectedDeviceId"
                [ngClass]="{'btn-default': !selectedDeviceId, 'btn-success': selectedDeviceId}"
                class="PhotoBootScatta btn pt-2 pb-0"><i class="mdi mdi-camera p-0"></i> Scatta foto
        </button>
        <div class="PhotoBootVideo">
          <webcam
            [videoOptions]="{width: {ideal: 1280}, height: {ideal: 1024}}"
            width="1280"
            height="1024"
            [device]="selectedDeviceId"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
          ></webcam>
          <div #countdown class="PhotoBootVideo_Countdown">{{timer}}</div>
        </div>
      </div>

      <form (submit)="saveHandler()" [formGroup]="form" class="PhotoBootPhotos">
        <div class="PhotoBootPhotos_Top">
          <ng-select [items]="clienti" formControlName="cliente_id" bindValue="id" [searchFn]="customSearchFn"
                     placeholder="Seleziona cliente">
            <ng-template ng-label-tmp let-item="item">
              {{item.cognome}} {{item.nome}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.cognome}} {{item.nome}}
            </ng-template>
          </ng-select>

          <div class="mt-4">
            <label class="radio">
              <input type="radio" formControlName="cartella" value="new">
              <span></span>Nuova cartella
            </label>
          </div>
          <div>
            <label class="radio">
              <input type="radio" formControlName="cartella" value="id">
              <span></span>Cartella esistente
            </label>
          </div>

          <select formControlName="id" class="form-control mt-1" *ngIf="form.value.cartella === 'id'">
            <option [ngValue]="null">Seleziona cartella</option>
            <option *ngFor="let cartella of cartelle" [value]="cartella.id">{{cartella.nome}}</option>
          </select>
          <input *ngIf="form.value.cartella === 'new'" type="text" class="form-control mt-1"
                 placeholder="Inserisci nome cartella" formControlName="nome">
        </div>
        <div class="PhotoBootPhotos_List">
          <foto-item *ngFor="let item of images; let imageIndex = index" [item]="item"
                     (onImageClick)="imageClickHandler($event, imageIndex)"
                     (onUpdate)="handleUpdate($event)"></foto-item>
        </div>
        <div class="PhotoBootPhotos_Bottom">
          <button (click)="onClose.emit()" type="button" class="btn btn-danger">Chiudi</button>
          <button [disabled]="form.invalid || !this.images.length" type="submit" class="btn btn-primary">Salva
            cartella
          </button>
        </div>
      </form>

      <div *ngIf="showImage" class="OverlayPhoto">
        <img src="{{showImage.fullUrl}}"/>
        <div class="OverlayPhoto_Buttons">
          <button (click)="deleteHandler(showImage)" class="btn btn-danger">Elimina</button>
          <button (click)="showImage = null" class="btn btn-success">OK</button>
        </div>
      </div>

    </div>
  `
})

export class PhotoBoothComponent implements OnInit {

  @Output() onClose = new EventEmitter();

  timer = 5; // seconds

  clienti = [];
  showImage = false;
  images = [];
  cartelle = [];
  selectedDeviceId = null;
  devices: MediaDeviceInfo[] = [];
  private trigger: Subject<void> = new Subject<void>();
  @ViewChild('countdown', {static: true}) countdown;

  form: FormGroup;

  constructor(
    store: Store,
    private modal: NgbModal,
    fb: FormBuilder,
    private service: FeatureService
  ) {
    store.dispatch(loadRecords());
    store.select(getRecords).subscribe(clienti => this.clienti = clienti);

    this.form = fb.group({
      cliente_id: fb.control('', [Validators.required]),
      id: fb.control('', []),
      nome: fb.control('', []),
      cartella: fb.control('new'),
    });

  }

  ngOnInit() {

    this.searchDevices();

    this.form.get('cliente_id').valueChanges.pipe(
      switchMap(value => this.service.all(value))
    ).subscribe((result: any[]) => {
      this.cartelle = result;
    });

  }

  searchDevices() {

    navigator.mediaDevices.enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        devices = devices.filter(item => item.kind === 'videoinput');
        this.devices = devices;

        if (devices.length === 0) {
          this.noDevicesHandler()
        } else if (!this.selectedDeviceId) {
          this.selectedDeviceId = devices[0].deviceId;
        }
      })

  }

  noDevicesHandler() {

  }

  public triggerSnapshot(): void {

    let index = 1;
    this.countdown.nativeElement.classList.add('show');
    this.countdown.nativeElement.innerText = this.timer - 1;
    const interval = setInterval(() => {
      index++;
      this.countdown.nativeElement.innerText = this.timer - index;
      if (index === this.timer - 1) {
        this.countdown.nativeElement.classList.remove('show');
        clearInterval(interval);
        this.trigger.next();
      }
    }, 1000);

  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {

    const id = new Date().getTime();

    const newImage = {
      base64: webcamImage.imageAsDataUrl,
      description: '',
      id: id
    }

    this.images = [...this.images, newImage];

    this.service.postTemp({
      base64: webcamImage.imageAsDataUrl
    }).subscribe(({record}: any) => {

      this.images = this.images.map((item) => {

        if (item.id !== id) return item;
        return record;

      });


    })

  }

  handleUpdate(item) {

    console.log('handleUpdate', item);

    this.images = this.images.map(image => image.id === item.id ? item : image).filter(item => !item.deleted);

    if (item.id && item.user_id) {

      if (item.deleted) {

        this.service.deleteTemp(item.id).subscribe(({record}: any) => {})

      } else {

        this.service.updateTemp(item.id, {
          description: item.description
        }).subscribe(({record}: any) => {
          this.images = this.images.map(image => image.id === item.id ? record : image).filter(item => !item.deleted);
        })

      }
    }

  }

  deleteHandler(item) {

    if (confirm('Confermi di voler procedere?')) {

      this.showImage = null;

      this.service.deleteTemp(item.id).subscribe(() => {
        this.images = this.images.filter(image => image.id !== item.id)
      })

    }

  }

  saveHandler() {

    const {id, nome, cartella, cliente_id} = this.form.value;

    const data = {
      id: cartella === 'new' ? 'new' : id,
      nome,
      // base64: this.images,
      fotoTemp: this.images
    };

    this.service.create(cliente_id, data).subscribe(() => {
      if (cartella === 'new') {
        this.service.all(cliente_id).subscribe((cartelle: any[]) => {
          this.cartelle = cartelle;
          const findCartella = cartelle.find(item => item.nome === nome);
          if (findCartella) {
            this.form.controls['cartella'].setValue('id');
            this.form.controls['id'].setValue(findCartella.id);
          }
        })
      }
      this.images = [];
    });

  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.nome.toLocaleLowerCase().indexOf(term) > -1 || item.cognome.toLocaleLowerCase().indexOf(term) > -1;
  }

  imageClickHandler(image, index) {
    this.showImage = image;
  }
}
