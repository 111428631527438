import { createFeatureSelector } from '@ngrx/store';
import { HttpStatus } from '../../../models/http-status';
import { Cliente } from '../../../models/Cliente';

export const featureName = 'Clienti';

export interface FeatureState {
  list: Cliente[],
  current: Cliente,
  errors: HttpStatus,
}

export const featureSelect = createFeatureSelector<FeatureState>('clienti');
