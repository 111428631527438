import { ApiService } from '../../../../core/store/base/api-service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class FeatureService extends ApiService {

    apiSegment = 'prodotti';

    updateSort(ids: any[]) {

        return this.http.post(`${this.apiSegment}/sort`, {
          _method: 'PUT',
          ids
        });

    }
}
