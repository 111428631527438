import { createSelector } from '@ngrx/store';
import { featureSelect, FeatureState } from '../settings';

export const getRecords = createSelector(
    featureSelect,
    (state: FeatureState) => state.list
);

export const getRecordById = createSelector(
    featureSelect,
    (state: FeatureState, recordId) => state.list.find(record => record.id.toString() === recordId.toString())
);
