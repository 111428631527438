import { createFeatureSelector } from '@ngrx/store';
import { Category, Prodotto } from '../../../models/Prodotto';
import { HttpStatus } from '../../../models/http-status';

export interface FeatureState {
    list: Prodotto[],
    categories: Category[],
    errors: HttpStatus
}

export const featureSelect = createFeatureSelector<FeatureState>('prodotti');
