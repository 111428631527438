import * as _isObject from 'lodash.isobject'
import * as _omit from 'lodash.omit';
import * as _isEmpty from 'lodash.isempty';
import * as _isArray from 'lodash.isarray';
import * as _orderBy from 'lodash.orderby';
import * as _sortBy from 'lodash.sortby';
import * as _capitalize from 'lodash.capitalize';

export const isObject = (obj: any) => _isObject(obj);

export const omit = (obj: object, keys: string[]) => _omit(obj, keys);

export const isEmpty = (obj) => _isEmpty(obj);

export const isArray = (array) => _isArray(array);

export const orderBy = (...params) => _orderBy(...params);

export const sortBy = (...params) => _sortBy(...params);

export const capitalize = (string: string) => _capitalize(string);

export const isValidDate = (date: string | null) => {
  return date && date.trim() !== '' && date.substr(0, 10) !== '0000-00-00'
};
