import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureState } from './feature.module';

export const featureSelect = createFeatureSelector<FeatureState>('settings');

export const getCategorieTrattamento = createSelector(
    featureSelect,
    (state: FeatureState) => !state.categorieTrattamento ? null : state.categorieTrattamento.filter(item => item.categoria === 'trattamento')
);

export const getCategorieMantenimento = createSelector(
    featureSelect,
    (state: FeatureState) => !state.categorieTrattamento ? null : state.categorieTrattamento.filter(item => item.categoria === 'mantenimento')
);

export const getTipologieTrattamento = createSelector(
    featureSelect,
    (state: FeatureState) => state.tipologieTrattamento
);
