/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./loader.component";
import * as i3 from "../services/loader.service";
var styles_LoaderComponent = ["@keyframes loader {\n            0% {\n                width: 0;\n                left: 0;\n            } \n            50% {\n                width: 100%;\n                left: 0;\n            } \n            100% {\n                left: 100%;\n            }\n        }\n        \n        .Loader[_ngcontent-%COMP%] {\n            position: fixed;\n            top: 0;\n            left: 0;\n            width: 100%;\n            height: 100%;\n            z-index: 99999;\n            overflow: hidden;\n            background: rgba(255,255,255,0.8);\n            opacity: 0;\n            visibility: hidden;\n            transition: all 0.3s;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n        \n        .Loader[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%] {\n            \n            background: #fff;\n            border-radius: 10px;\n            padding: 20px 30px;\n            box-shadow: 0 0 10px rgba(0,0,0,0.1);\n            \n        }\n        \n        \n        \n        .Loader.Loader--loading[_ngcontent-%COMP%] {\n            opacity: 1;\n            visibility: visible;\n        }"];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "Loader"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(4, { "Loader--loading": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Attendere prego..."])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "spinner-border text-secondary mt-3"], ["role", "status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Loader"; var currVal_1 = _ck(_v, 4, 0, i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_co.isLoading))); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoaderComponent, [i3.LoaderService], null, null)], null, null); }
var LoaderComponentNgFactory = i0.ɵccf("app-loader", i2.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
