import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
  pure: false
})

// https://momentjs.com/docs/#/parsing/string-format/

export class DateFormatPipe implements PipeTransform {
  transform(date: any, format: string = 'DD/MM/yyyy'): any {
    if (!date || date === '') return '-';

    if (format.indexOf('dddd') === -1 && format.indexOf('dd') !== -1) {
      format = format.replace('dd', 'DD');
    }

    format = format.replace('EEEE', 'dddd');

    if (date) {
      return moment(date).format(format);
    }
    return date;
  }
}
