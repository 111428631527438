import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoBoothComponent } from './photo-booth.component';
// import { WebcamModule } from 'ngx-webcam';
import { WebcamModule } from './webcam/webcam.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../app/shared/shared.module';
import { NuovaCartellaComponent } from './nuova-cartella.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    PhotoBoothComponent,
    NuovaCartellaComponent
  ],
  imports: [
    CommonModule,
    WebcamModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [PhotoBoothComponent],
  entryComponents: [NuovaCartellaComponent]
})

export class PhotoBoothModule { }
