import { AppState } from '../store.module';
import { createSelector } from '@ngrx/store';
import { State } from './index';

export const selectUI = (state: AppState) => state.ui;

export const isMenuHidden = createSelector(
    selectUI,
    (ui: State) => ui.isMenuHidden
)
