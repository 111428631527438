import { CanActivate} from '@angular/router';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getToken } from '../store/auth/selectors';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { go } from '../store/router/actions';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store
    ) {}

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(getToken),
            map((token) => !!token),
            distinctUntilChanged(),
            tap(isLogged => {
                if(!isLogged) {
                    this.store.dispatch(go({path: '/auth/login'}))
                }
            })
        );
    }
}
