import { Injectable } from '@angular/core';
import { ApiClient } from '../../../../../core/services/api.service';

@Injectable({
  providedIn: 'root',
})

export class FeatureService {

  private apiSegment = 'users';

  constructor(
    private http: ApiClient
  ) {
  }

  all() {

    return this.http.get(`${this.apiSegment}`);

  }

  create(data) {

    return this.http.post(`${this.apiSegment}`, data);

  }

  item(id) {

    return this.http.get(`${this.apiSegment}/${id}`);

  }

  update(id, data) {

    if (data instanceof FormData) {
      data.append('_method', 'PUT');
    } else {
      data = Object.assign({}, data, {'_method': 'PUT'});
    }

    return this.http.post(`${this.apiSegment}/${id}`, data);

  }

  patch(id, data) {

    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
    } else {
      data = Object.assign({}, data, {'_method': 'PATCH'});
    }

    return this.http.post(`${this.apiSegment}/${id}`, data);

  }

  config(id, data) {

    data = Object.assign({}, data, {'_method': 'put'});
    return this.http.post(`${this.apiSegment}/${id}/config`, data);

  }

  delete(id) {

    return this.http.delete(`${this.apiSegment}/${id}`);

  }

}
