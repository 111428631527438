import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {saveRecord} from '../store/list/actions';
import {getRecords} from '../store/categories/selectors';
import {Prodotto} from '../../../models/Prodotto';
import { hasCustomProductsPermission } from '../../../core/store/account/selectors';

@Component({
  styles: [``],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ record ? 'Modifica' : 'Nuovo' }} prodotto</h4>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" (submit)="submitHandler($event)">
        <div class="form-group row">
          <label class="col-form-label">Linea *</label>
          <div class="control-input">
            <ng-select
              [items]="categories"
              formControlName="category_id"
              bindLabel="name"
              bindValue="id"
              [closeOnSelect]="true"
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="CategoryBox">
                  <div class="CategoryBox_Color" [style.background]="item.color"></div>
                  <div class="CategoryBox_Text">{{item.name}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label">Nome *</label>
          <div class="control-input">
            <input class="form-control" type="text" formControlName="name" [ngClass]="{'is-invalid': errors?.name }">
            <span *ngIf="errors?.name"
                  class="invalid-feedback"
                  role="alert">
                    {{ errors?.name[0] }}
                </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label">Prezzo *</label>
          <div class="control-input">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">&euro;</span></div>
              <input class="form-control" type="number" step="0.1" formControlName="price" [ngClass]="{'is-invalid': errors && errors?.price }">
            </div>
            <span *ngIf="errors?.price" class="invalid-feedback" role="alert">{{ errors?.price[0] }}</span>
          </div>
        </div>

        <div class="form-group row" *ngIf="hasCustomProductsPermission$ | async">
          <label class="col-form-label">Solo vendita</label>
          <div class="control-input">
            <div>
              <span class="switch">
                  <label>
                      <input name="enabled" formControlName="only_shop" type="checkbox" checked="checked" value="1">
                      <span></span>
                  </label>
              </span>
            </div>
            <p><small style="line-height: 1em">Se la casella è spuntata il prodotto non sarà selezionabile all'interno della scheda trattamento</small></p>
          </div>
        </div>

      </form>

    </div>
    <div class="modal-footer">
      <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
      <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
    </div>
  `
})

export class ItemModalComponent implements OnInit {
  public userId = null;
  public record: Prodotto | null = null;
  public errors = null;
  form: FormGroup;
  categories = [];

  hasCustomProductsPermission$ = this.store.select(hasCustomProductsPermission);

  constructor(
    fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store
  ) {

    this.form = fb.group({
      id: fb.control(null, []),
      user_id: fb.control(null, []),
      category_id: fb.control(null, []),
      price: fb.control(null, [Validators.required]),
      name: fb.control(null, [Validators.required]),
      only_shop: fb.control(0)
    });

    this.store.select(getRecords).subscribe(categories => {
      this.categories = categories;
    });
  }

  ngOnInit(): void {
    if (this.record) {
      this.form.patchValue(this.record);
    }
  }

  submitHandler(e) {
    this.store.dispatch(saveRecord({record: this.form.value}));
  }
}
