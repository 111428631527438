import { Action, createReducer, on } from '@ngrx/store';
import {
    setCurrent
} from './actions';
import {Cliente} from '../../../../models/Cliente';
import {logoutSuccess} from '../../../../core/store/auth/actions';
import { updateRecordSuccess } from '../list/actions';

const initialState: Cliente = null;

const featureReduce = createReducer(
    initialState,
    on(setCurrent, (state, {record}) => record),
    on(updateRecordSuccess, (state, {record}) => state.id === record.id ? record : state ),
    on(logoutSuccess, () => initialState),
);

export function reducer (state: Cliente | null, action?: Action) {
    return featureReduce(state, action);
}
