import {Component} from "@angular/core";
import {LoaderService} from "../services/loader.service";
import {Subject} from "rxjs";

@Component({
    selector: 'app-loader',
    template: `
        <div class="Loader" [ngClass]="{'Loader--loading': (isLoading | async)}">
            <div class="text-center">
                Attendere prego...<br>
                <div class="spinner-border text-secondary mt-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    `, // (isLoading | async)
    styles: [`
        
        @keyframes loader {
            0% {
                width: 0;
                left: 0;
            } 
            50% {
                width: 100%;
                left: 0;
            } 
            100% {
                left: 100%;
            }
        }
        
        .Loader {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99999;
            overflow: hidden;
            background: rgba(255,255,255,0.8);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .Loader > div {
            
            background: #fff;
            border-radius: 10px;
            padding: 20px 30px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            
        }
        
        /*
        .Loader::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 10px;
            background: #000;
        }
        */
        
        .Loader.Loader--loading {
            opacity: 1;
            visibility: visible;
        }

        /*
        .Loader.Loader--loading::before {
            animation: loader 1s linear infinite;
        }
        */
        
    `]
})

export class LoaderComponent {
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    constructor(private loaderService: LoaderService){}
}