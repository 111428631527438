/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/menu";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i11 from "./topbar.component";
import * as i12 from "@ngrx/store";
var styles_TopbarComponent = ["@media only print {\n            #kt_header[_ngcontent-%COMP%] {\n                display: none;\n            }\n        }"];
var RenderType_TopbarComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TopbarComponent, data: {} });
export { RenderType_TopbarComponent as RenderType_TopbarComponent };
function View_TopbarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge rounded-pill badge-secondary ml-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unreadNotifications; _ck(_v, 1, 0, currVal_0); }); }
export function View_TopbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 54, "div", [["class", "header header-fixed"], ["id", "kt_header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 53, "div", [["class", "container-fluid d-flex align-items-center justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "header-menu-wrapper header-menu-wrapper-left"], ["id", "kt_header_menu_wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showCamHandler.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Apri MICROCAMERA"])), (_l()(), i0.ɵeld(6, 0, null, null, 48, "div", [["class", "topbar justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "topbar-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "btn p-0 burger-icon"], ["id", "kt_header_mobile_toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleToggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "/assets/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 43, "div", [["class", "topbar-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "a", [["class", "btn"], ["routerLink", "/notifications"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-bell p-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopbarComponent_1)), i0.ɵdid(16, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 16777216, null, null, 7, "button", [["aria-haspopup", "true"], ["class", "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mat-menu-trigger"], ["id", "kt_quick_user_toggle"], ["mat-button", ""]], [[1, "aria-expanded", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 18)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 18)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(18, 1196032, null, 0, i4.MatMenuTrigger, [i5.Overlay, i0.ElementRef, i0.ViewContainerRef, i4.MAT_MENU_SCROLL_STRATEGY, [2, i4.MatMenu], [8, null], [2, i6.Directionality], i7.FocusMonitor], { menu: [0, "menu"] }, null), i0.ɵdid(19, 180224, null, 0, i8.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(20, 0, null, 0, 1, "span", [["class", "text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["", ""])), (_l()(), i0.ɵeld(22, 0, null, 0, 2, "span", [["class", "symbol symbol-35 symbol-light-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "span", [["class", "symbol-label font-size-h5 font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 0, "i", [["class", "mdi mdi-account"], ["style", "padding-top: 7px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 29, "mat-menu", [["x-placement", "bottom-end"], ["xPosition", "before"]], null, null, null, i10.View__MatMenu_0, i10.RenderType__MatMenu)), i0.ɵprd(6144, null, i4.MatMenu, null, [i4._MatMenu]), i0.ɵprd(6144, null, i4.MAT_MENU_PANEL, null, [i4.MatMenu]), i0.ɵdid(28, 1294336, [["userMenu", 4]], 3, i4._MatMenu, [i0.ElementRef, i0.NgZone, i4.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], panelClass: [1, "panelClass"] }, null), i0.ɵqud(603979776, 1, { _allItems: 1 }), i0.ɵqud(603979776, 2, { items: 1 }), i0.ɵqud(603979776, 3, { lazyContent: 0 }), (_l()(), i0.ɵeld(32, 0, null, 0, 22, "div", [["class", "navi navi-spacer-x-0 pt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 5, "a", [["class", "navi-item px-8"], ["routerLink", "/account"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(34, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(35, 0, null, null, 3, "div", [["class", "navi-link"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 2, "div", [["class", "navi-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 1, "div", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Account"])), (_l()(), i0.ɵeld(39, 0, null, null, 5, "a", [["class", "navi-item px-8"], ["routerLink", "/account/password"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(40, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(41, 0, null, null, 3, "div", [["class", "navi-link"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 2, "div", [["class", "navi-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 1, "div", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Modifica password"])), (_l()(), i0.ɵeld(45, 0, null, null, 5, "a", [["class", "navi-item px-8"], ["routerLink", "/account/calendario"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 46).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(46, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(47, 0, null, null, 3, "div", [["class", "navi-link"]], null, null, null, null, null)), (_l()(), i0.ɵeld(48, 0, null, null, 2, "div", [["class", "navi-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(49, 0, null, null, 1, "div", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Impostazioni"])), (_l()(), i0.ɵeld(51, 0, null, null, 0, "div", [["class", "navi-separator mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(52, 0, null, null, 2, "div", [["class", "navi-footer px-8 py-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(53, 0, null, null, 1, "button", [["class", "btn btn-light-primary font-weight-bold"], ["style", "width: 100%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Esci "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/notifications"; _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.unreadNotifications > 0); _ck(_v, 16, 0, currVal_3); var currVal_7 = i0.ɵnov(_v, 28); _ck(_v, 18, 0, currVal_7); var currVal_9 = "before"; var currVal_10 = "menu-utente"; _ck(_v, 28, 0, currVal_9, currVal_10); var currVal_13 = "/account"; _ck(_v, 34, 0, currVal_13); var currVal_16 = "/account/password"; _ck(_v, 40, 0, currVal_16); var currVal_19 = "/account/calendario"; _ck(_v, 46, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 13).target; var currVal_1 = i0.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_0, currVal_1); var currVal_4 = (i0.ɵnov(_v, 18).menuOpen || null); var currVal_5 = (i0.ɵnov(_v, 19).disabled || null); var currVal_6 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.userNameFull; _ck(_v, 21, 0, currVal_8); var currVal_11 = i0.ɵnov(_v, 34).target; var currVal_12 = i0.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_11, currVal_12); var currVal_14 = i0.ɵnov(_v, 40).target; var currVal_15 = i0.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_14, currVal_15); var currVal_17 = i0.ɵnov(_v, 46).target; var currVal_18 = i0.ɵnov(_v, 46).href; _ck(_v, 45, 0, currVal_17, currVal_18); }); }
export function View_TopbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i0.ɵdid(1, 49152, null, 0, i11.TopbarComponent, [i1.Router, i12.Store], null, null)], null, null); }
var TopbarComponentNgFactory = i0.ɵccf("app-topbar", i11.TopbarComponent, View_TopbarComponent_Host_0, {}, { showCamHandler: "showCamHandler" }, []);
export { TopbarComponentNgFactory as TopbarComponentNgFactory };
