/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nuova-cartella.component";
var styles_NuovaCartellaComponent = [];
var RenderType_NuovaCartellaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NuovaCartellaComponent, data: {} });
export { RenderType_NuovaCartellaComponent as RenderType_NuovaCartellaComponent };
export function View_NuovaCartellaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" - cliente"])), (_l()(), i0.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" - nome cartella "]))], null, null); }
export function View_NuovaCartellaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NuovaCartellaComponent_0, RenderType_NuovaCartellaComponent)), i0.ɵdid(1, 49152, null, 0, i1.NuovaCartellaComponent, [], null, null)], null, null); }
var NuovaCartellaComponentNgFactory = i0.ɵccf("ng-component", i1.NuovaCartellaComponent, View_NuovaCartellaComponent_Host_0, {}, {}, []);
export { NuovaCartellaComponentNgFactory as NuovaCartellaComponentNgFactory };
