/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./webcam.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./webcam.component";
var styles_WebcamComponent = [i0.styles];
var RenderType_WebcamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebcamComponent, data: {} });
export { RenderType_WebcamComponent as RenderType_WebcamComponent };
export function View_WebcamComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { video: 0 }), i1.ɵqud(402653184, 2, { canvas: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "webcam-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.imageClick.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["video", 1]], null, 0, "video", [["autoplay", ""], ["muted", ""], ["playsinline", ""]], [[8, "width", 0], [8, "height", 0], [8, "className", 0]], [[null, "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resize" === en)) {
        var pd_0 = (_co.videoResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, [[2, 0], ["canvas", 1]], null, 0, "canvas", [], [[8, "width", 0], [8, "height", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoWidth; var currVal_1 = _co.videoHeight; var currVal_2 = _co.videoStyleClasses; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.width; var currVal_4 = _co.height; _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_WebcamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "webcam", [], null, null, null, View_WebcamComponent_0, RenderType_WebcamComponent)), i1.ɵdid(1, 4898816, null, 0, i2.WebcamComponent, [], null, null)], null, null); }
var WebcamComponentNgFactory = i1.ɵccf("webcam", i2.WebcamComponent, View_WebcamComponent_Host_0, { device: "device", width: "width", height: "height", videoOptions: "videoOptions", allowCameraSwitch: "allowCameraSwitch", mirrorImage: "mirrorImage", captureImageData: "captureImageData", imageType: "imageType", imageQuality: "imageQuality", trigger: "trigger", switchCamera: "switchCamera" }, { imageCapture: "imageCapture", initError: "initError", imageClick: "imageClick", cameraSwitched: "cameraSwitched" }, []);
export { WebcamComponentNgFactory as WebcamComponentNgFactory };
