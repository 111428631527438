import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { updateConfig } from '../store/list/actions';
import { Store } from '@ngrx/store';
import { getErrors } from '../store/errors/selectors';

@Component({
  template: `

    <div class="modal-header">
      <h4 class="modal-title">Configurazione</h4>
    </div>
    <div class="modal-body">

      <p>
        <strong>ID Utente</strong>: {{ record?.id }}
      </p>

      <form [formGroup]="form" action="">

        <div *ngIf="errors && errors.message" class="alert alert-danger">{{ errors.message }}</div>


        <div formGroupName="smshosting">

          <h3 class="mb-4">Configurazione Smshosting</h3>

          <div class="form-group row">
            <label class="col-form-label">Status</label>
            <div class="control-input">
              <select formControlName="status" class="form-control">
                <option value="">Seleziona</option>
                <option value="active">Attivo</option>
                <option value="pending">Non attivo</option>
              </select>
              <span *ngIf="errors?.username" class="invalid-feedback" role="alert">{{ errors?.username[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Username</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="username"
                     name="username"
                     [ngClass]="{'is-invalid': errors?.username }">
              <span *ngIf="errors?.username" class="invalid-feedback" role="alert">{{ errors?.username[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Password</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="password"
                     name="password"
                     [ngClass]="{'is-invalid': errors?.password }">
              <span *ngIf="errors?.password" class="invalid-feedback" role="alert">{{ errors?.password[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">API KEY</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="api_key"
                     name="api_key"
                     [ngClass]="{'is-invalid': errors?.api_key }">
              <span *ngIf="errors?.api_key" class="invalid-feedback" role="alert">{{ errors?.api_key[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">API Secret</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="api_secret"
                     name="api_secret"
                     [ngClass]="{'is-invalid': errors?.api_secret }">
              <span *ngIf="errors?.api_secret" class="invalid-feedback" role="alert">{{ errors?.api_secret[0] }}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Sender</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="sender"
                     name="sender"
                     [ngClass]="{'is-invalid': errors?.sender }">
              <span *ngIf="errors?.sender" class="invalid-feedback" role="alert">{{ errors?.sender[0] }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
      <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
    </div>
  `
})

export class ConfigModalComponent implements OnInit {
  public record = null;
  form: FormGroup;
  errors = null;

  constructor(
    fb: FormBuilder,
    private store: Store,
    public modal: NgbActiveModal
  ) {

    this.form = fb.group({
      smshosting: fb.group({
        status: fb.control(null),
        username: fb.control(null, []),
        password: fb.control(null, []),
        api_key: fb.control(null, []),
        api_secret: fb.control(null, []),
        sender: fb.control(null, []),
      })
    })

    this.store.select(getErrors).subscribe((errors) => this.errors = errors);

  }

  ngOnInit(): void {

    this.form.patchValue({
      id: this.record.id,
      smshosting: {
        status: this.record.smshosting ? this.record.smshosting.status : 'pending',
        username: this.record.smshosting ? this.record.smshosting.settings.username : null,
        password: this.record.smshosting ? this.record.smshosting.settings.password : null,
        api_key: this.record.smshosting ? this.record.smshosting.settings.api_key : null,
        api_secret: this.record.smshosting ? this.record.smshosting.settings.api_secret : null,
        sender: this.record.smshosting ? this.record.smshosting.settings.sender : null,
      }
    })

  }

  submitHandler(e) {

    const { smshosting } = this.form.value;

    const value = {
      id: this.record.id,
      smshosting
    }

    this.store.dispatch(updateConfig({record: value}))
  }


}
