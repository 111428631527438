import { Component, ElementRef, ViewChild } from '@angular/core';
import { IModalDialog, IModalDialogButton } from '../../../../../packages/ngx-modal-master';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Model } from '../../models';
import { DateFormatPipe as DatePipe } from '../../../../shared/pipes/dateformat.pipe';
import { FeatureService } from '../../../../features/assistenza/feature.service';
import { FeatureService as CheckupService} from '../../../clienti/subfeatures/checkup/feature.service';
import { FeatureService as ClienteService} from '../../../clienti/feature.service';

@Component({
    providers: [DatePipe],
    styles: [`
      .MessagesContainer {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 182px);
        overflow: hidden;
      }

      .MessagesContainer_List {
        flex: 1;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }

      .MessagesContainer_Form {
        border-top: 1px solid #ECF0F3;
        margin-top: 20px;
        padding-top: 20px;
        min-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .MessagesContainer_Form form {
        flex: 1;
        /*margin-right: 20px;*/
      }

      .MessagesContainer_Form form textarea {
        resize: none;
      }

      .MessagesContainer_Photos {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      .MessagesContainer_Photos > a {
        width: calc(100% / 4);
        border: 2px solid #d0d0d0;
        margin: 5px;
        display: flex;
        background: #eaeaea;
        align-items: center;
      }

      .MessagesContainer_Photos > a img {
        width: 100%;
        height: auto;
      }
    `],
    template: `
        <div class="MessagesContainer">
            <div class="MessagesContainer_List" #messagesView>
                <div class="messages">
                    <div *ngIf="!isAdmin && record.cliente_id" class="mb-5 rounded p-5 font-weight-bold font-size-lg bg-light-primary">
                        La richiesta si riferisce a: {{ record.cliente.cognome }} {{ record.cliente.nome }}. <br />
                        L'assistenza potrà vederne la scheda completa in forma anonima.
                    </div>

                    <div *ngIf="isAdmin" class="mb-5 rounded p-5 font-weight-bold font-size-lg bg-light-dark">
                      <p>Richiesta inviata da <strong>{{ detail.ticket.user.nome }} {{ detail.ticket.user.cognome }}</strong> il <strong>{{ detail.ticket.created_at | dateFormat: 'dd/MM/yyyy' }}</strong></p>
                      <ng-container *ngIf="record.cliente_id">
                        L'operatore ha selezionato un cliente per questa richiesta, cliccando sul bottone "Visualizza cliente" sarà possibile vedere tutti i trico check e le foto:<br>
                        <button type="button" class="btn btn-sm btn-default mt-2" (click)="handleShowSchedaCliente()">Visualizza cliente</button>
                      </ng-container>
                    </div>

                    <div *ngFor="let item of messages" class="d-flex flex-column mb-5 align-items-start" [ngClass]="{'align-items-end': item.type === 'owner'}">
                        <div class="mt-2 rounded p-5 font-weight-bold font-size-lg text-left max-w-400px" [ngClass]="{'bg-light-primary': item.type === 'owner', 'text-dark': item.type !== 'owner', 'bg-light-dark': item.type !== 'owner' }">
                            {{ item.message }}
                        </div>
                      <div *ngIf="item.photos.length" class="MessagesContainer_Photos" [ngClass]="{'justify-content-end': item.type === 'owner'}">
                        <a target="_blank" [href]="foto.fullUrl" *ngFor="let foto of item.photos">
                          <img [src]="foto.fullUrl" />
                        </a>
                      </div>
                      <div class="d-flex align-items-center mt-2">
                        <span class="text-muted font-size-sm ml-2"><ng-container *ngIf="item.type !== 'owner'">{{item.user_name}} - </ng-container>{{ item.created_at | dateFormat: 'dd/MM/yyyy HH:mm' }}</span>
                      </div>
                    </div>
                </div>
            </div>
            <div class="MessagesContainer_Form">
              <ng-container *ngIf="archived" class="text-center">Non è possibile rispondere alla richiesta in quanto risulta archiviata</ng-container>
                <form [formGroup]="myForm" (submit)="handleSubmit()"  action="" *ngIf="!archived">
                    <textarea [ngClass]="{'is-invalid': errors?.message }" formControlName="message" class="form-control border-0 p-0" placeholder="Risposta..."></textarea>
                  <span *ngIf="errors?.message"
                        class="invalid-feedback"
                        role="alert">
                    {{ errors?.message[0] }}
                </span>
                </form>
            </div>
        </div>
    `
})

export class MessagesComponent implements IModalDialog {

    @ViewChild('form', {static: true}) form: NgForm;
    myForm: FormGroup;
    actionButtons: IModalDialogButton[];
    response = null;
    record: Model = null;
    options = null;
    errors = null;
    detail = null;
    messages = [];
    isAdmin = false;
    archived = false;

    @ViewChild('messagesView', {static: true}) messagesView: ElementRef;

    constructor(
        private datePipe: DatePipe,
        private service: FeatureService,
        private clienteService: ClienteService,
        private checkupService: CheckupService,
        fb: FormBuilder
    ) {

        this.myForm = fb.group({
          message: fb.control('')
        })

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    dialogInit(reference, options) {
        this.options = options.data;
        this.record = options.data.record;
        this.detail = options.data.response;
        this.messages = this.detail.messages;
        this.archived = options.data.archived;
        this.isAdmin = options.data.isAdmin;

        options.onValidate.subscribe({
            next: ({ errors }) => {
              this.errors = errors
            }
        });

        const div = this.messagesView.nativeElement;
        setTimeout(() => {
            div.scrollTop = div.scrollHeight;
        }, 10)

        if (!this.archived) {
          this.actionButtons = [
            {text: 'Annulla', buttonClass: 'btn btn-default'},
            {text: 'Rispondi', buttonClass: 'btn btn-primary', onAction: this.handleSubmit}
          ];
        } else {
          this.actionButtons = [
            {text: 'Chiudi', buttonClass: 'btn btn-default'},
          ];
        }

    }

    handleSubmit() {

        const formData = Object.assign({}, this.myForm.value);
        this.errors = null;
        return this.service.reply(this.record.id, formData);

    }

  handleShowSchedaCliente() {


      const url = `${window.location.protocol}//${window.location.host}/clienti/${this.record.cliente_id}?scope=assistenza`;
      window.open(url);

  }

}
