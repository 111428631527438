/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./laravel-errors.component";
var styles_LaravelErrorsComponent = ["ul[_ngcontent-%COMP%] {\n      margin: 5px 0 0 0;\n    }"];
var RenderType_LaravelErrorsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LaravelErrorsComponent, data: {} });
export { RenderType_LaravelErrorsComponent as RenderType_LaravelErrorsComponent };
function View_LaravelErrorsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value[0]; _ck(_v, 1, 0, currVal_0); }); }
function View_LaravelErrorsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_LaravelErrorsComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.errors.errors)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.message; _ck(_v, 2, 0, currVal_0); }); }
export function View_LaravelErrorsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LaravelErrorsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors && _co.errors.errors); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LaravelErrorsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-laravel-errors", [], null, null, null, View_LaravelErrorsComponent_0, RenderType_LaravelErrorsComponent)), i0.ɵdid(1, 49152, null, 0, i2.LaravelErrorsComponent, [], null, null)], null, null); }
var LaravelErrorsComponentNgFactory = i0.ɵccf("app-laravel-errors", i2.LaravelErrorsComponent, View_LaravelErrorsComponent_Host_0, { errors: "errors" }, {}, []);
export { LaravelErrorsComponentNgFactory as LaravelErrorsComponentNgFactory };
