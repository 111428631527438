import { createAction, props } from '@ngrx/store';
import { Prodotto } from '../../../../models/Prodotto';
import { HttpStatus } from '../../../../models/http-status';

const area = '[Products]'

/**
 * Get records actions
 */

export const loadRecords = createAction(
    `${area} Load records`,
    props<{ reload?: boolean }>()
);

export const loadRecordsSuccess = createAction(
    `${area} Load records success`,
    props<{ records: Prodotto[] }>()
);

export const loadRecordsFail = createAction(
    `${area} Load records fail`,
    props<any>()
);

export const saveRecord = createAction(
    `${area} Save record`,
    props<{ record: Prodotto }>()
);

export const saveRecordFail = createAction(
    `${area} Save record fail`,
    props<{ httpStatus: HttpStatus }>()
);

export const saveRecordSuccess = createAction(
    `${area} Save record success`,
);

/**
 * Add record actions
 */

export const addRecord = createAction(
    `${area} Add record`,
    props<{ record: Prodotto }>()
);

export const addRecordSuccess = createAction(
    `${area} Add record success`,
    props<{ record: Prodotto }>()
);

// export const addRecordFail = createAction(
//     `${area} Add record failed`,
//     props<any>()
// );

/**
 * Update record actions
 */

export const updateRecord = createAction(
    `${area} Update record`,
    props<{ record: Prodotto }>()
);

export const updateRecordSuccess = createAction(
    `${area} Update record success`,
    props<{ record: Prodotto }>()
);

// export const updateRecordFail = createAction(
//     `${area} Update record failed`,
//     props<any>()
// );

/**
 * Delete record actions
 */

export const deleteRecord = createAction(
    `${area} Delete record`,
    props<{ id: number }>()
);

export const deleteRecordSuccess = createAction(
    `${area} Delete record success`,
    props<{ id: number }>()
);


/**
 * Set current record
 */

export const setCurrentRecord = createAction(
    `${area} Set current record`,
    props<{ id: number }>()
);

export const updatePrivacyFile = createAction(
    `${area} Update Privacy File`,
    props<{ id: number, file: any }>()
);


/**
 * Modal
 */

export const showModal = createAction(
    `${area} Open modal`,
    props<{ id?: number, userId: number }>()
);

export const closeModal = createAction(
    `${area} Close modal`,
);


export const updateSort = createAction(
    `${area} Update sort`,
    props<{ ids: number[] }>()
);

