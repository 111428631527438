import { createFeatureSelector } from '@ngrx/store';
import { HttpStatus } from '../../../../models/http-status';
import { Model } from '../models';

export const featureName = '[Utenti]';

export interface FeatureState {
    list: Model[],
    errors: HttpStatus
}

export const featureSelect = createFeatureSelector<FeatureState>('utenti');
