import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DateFormatPipe as DatePipe } from '../pipes/dateformat.pipe';
import * as moment from 'moment';

import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatterProvider extends CalendarDateFormatter {

    public weekViewHour({date, locale}: DateFormatterParams): string {
      return new DatePipe().transform(date, 'HH:mm');
    }

    public dayViewHour({date, locale}: DateFormatterParams): string {
      return new DatePipe().transform(date, 'HH:mm');
    }

    public weekViewColumnSubHeader({date, locale}: DateFormatterParams): string {
        return new DatePipe().transform(date, 'D dddd');
    }

}
