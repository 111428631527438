
    <div [ngClass]="{
            'header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable': isLogged,
            'aside-minimize': isMenuHidden
        }">
      <div [ngClass]="{'d-flex flex-column flex-root': isLogged}">
        <div [ngClass]="{'d-flex flex-row flex-column-fluid page': isLogged}">
          <app-menu [vertical]="false" *ngIf="isLogged"></app-menu>
          <div [ngClass]="{'d-flex flex-column flex-row-fluid wrapper': isLogged}">
            <app-topbar (showCamHandler)="showTricoCam = true" *ngIf="isLogged"></app-topbar>
            <div [ngClass]="{'content': isLogged}">
              <router-outlet (activate)="handleRouteChange()"></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <app-photo-booth (onClose)="showTricoCam = false" *ngIf="showTricoCam"></app-photo-booth>
    </div>
    <app-notifications></app-notifications>
    <app-loader></app-loader>
  