
        <div class="modal-header">
            <h4 class="modal-title">{{ record ? 'Modifica' : 'Nuova' }} linea</h4>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (submit)="submitHandler($event)">
                <div class="row form-group">
                    <label class="col-form-label">Nome</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="name" [ngClass]="{'is-invalid': errors?.name }">
                        <span *ngIf="errors?.name"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.name[0] }}
                </span>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-form-label">Colore</label>
                    <div class="control-input">
                        <input
                                placeholder="Seleziona colore"
                                [colorPicker]="form.get('color').value"
                                [value]="form.get('color').value"
                                [style.background]="form.controls['color'].value"
                                (colorPickerChange)="form.get('color').setValue($event)"
                                class="form-control BoxColor colorPicker"
                                [ngClass]="{'BoxColor--selected': form.controls['color'].value}"
                                formControlName="color">

                        <div><small>Clicca sul campo per selezionare un colore</small></div>

                        <span *ngIf="errors?.color"
                              class="invalid-feedback"
                              role="alert">
                    {{ errors?.color[0] }}
                </span>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer">
            <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
            <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
        </div>
    