import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
    selector: 'app-actions-dropdown',
    template: `
        <div class="btn-group" [ngClass]="{'open show': opened}">
            <button (click)="handleToggleShow()" type="button" class="btn btn-default dropdown-toggle"
                    aria-controls="dropdown-basic">
                Azioni <span class="flaticon-more"></span>
            </button>
            <ul id="dropdown-basic" [ngClass]="{'show': opened}" class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                <ng-container role="menuitem" *ngFor="let action of actions">
                    <li *ngIf="!action.hide || !action.hide(record)">
                        <a *ngIf="action.isLink && !action.externalLink && (!action.hide || !action.hide(record))" routerLink="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.class" class="dropdown-item">{{ action.label}}</a>
                        <a target="_blank" *ngIf="action.isLink && action.externalLink && (!action.hide || !action.hide(record))" href="{{ action.url ? action.url(record) : '' }}" [ngClass]="action.class" class="dropdown-item">{{ action.label}}</a>
                        <button *ngIf="!action.isLink && (!action.hide || !action.hide(record))" class="dropdown-item" [ngClass]="action.class" (click)="handleActionClick(action, $event)">{{ action.label }}</button>
                    </li>
                </ng-container>
            </ul>
        </div>
    `
})

export class ActionsDropdownComponent {
    opened = false;
    @Input() record = null;
    @Input() actions = [];

    constructor(private eRef: ElementRef) {
    }

    handleToggleShow() {
        this.opened = !this.opened;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(!this.eRef.nativeElement.contains(event.target) && this.opened) {
            this.handleToggleShow();
        }
    }

    handleActionClick(action, event) {
        this.opened = false;
        action.click(this.record, event)
    }

}