import { Injectable} from '@angular/core';
import { ApiClient } from '../../core/services/api.service';

@Injectable({
    providedIn: 'root',
})

export class AccountService {

    constructor(
        private http: ApiClient
    ) {
    }

    get() {

        return this.http.get('account');

    }

    update(data) {

        return this.http.post(`account`, data);

    }

    getTipologieTrattamenti() {

        return this.http.get('account/tipologie-trattamenti');

    }

    getCategorieTrattamenti(tipologia = 'trattamento') {

        return this.http.get(`account/categorie-trattamenti?tipologia=${tipologia}`);

    }

    calendar({date, type, escludiTrattamento = null}) {

        return this.http.get(`account/calendar?date=${date}&type=${type}${escludiTrattamento ? '&escludiTrattamento=' + escludiTrattamento : ''}`);

    }

    searchCalendar({query}) {

        return this.http.get(`account/calendar?query=${query}`);

    }

    settings() {

        return this.http.get(`account/settings`);

    }

    updateSettings(data) {

        return this.http.put(`account/settings`, {data});

    }

    updatePassword(data) {

        return this.http.put(`account/password`, data);

    }


}
