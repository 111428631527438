import { createAction, props } from '@ngrx/store';
import { CategoriaTrattamento } from '../../../models/CategoriaTrattamento';
import { TipologiaTrattamento } from '../../../models/TipologiaTrattamento';

const area = '[Settings]'

/**
 * Get records actions
 */

export const loadRecords = createAction(
    `${area} Load records`,
);

export const loadRecordsSuccess = createAction(
    `${area} Load records success`,
    props<{ categorieTrattamento: CategoriaTrattamento[], tipologieTrattamento: TipologiaTrattamento[] }>()
);

