import { Model } from './models';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class FeatureStore {
    records: Model[];
    current: Model;
}