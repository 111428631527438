import { createAction, props } from '@ngrx/store';
import { Model } from '../../models';
import { HttpStatus} from '../../../../../models/http-status';
import { featureName } from '../settings';

const area = `${featureName}`;

/**
 * Get records actions
 */

export const loadRecords = createAction(
    `${area} Load records`,
);

export const loadRecordsSuccess = createAction(
    `${area} Load records success`,
    props<{ records: Model[] }>()
);

export const loadRecordsFail = createAction(
    `${area} Load records fail`,
    props<any>()
);

export const saveRecord = createAction(
    `${area} Save record`,
    props<{ record: Model }>()
);

export const saveRecordFail = createAction(
    `${area} Save record fail`,
    props<{ httpStatus: HttpStatus }>()
);

export const saveRecordSuccess = createAction(
    `${area} Save record success`,
);

/**
 * Add record actions
 */

export const addRecord = createAction(
    `${area} Add record`,
    props<{ record: Model }>()
);

export const addRecordSuccess = createAction(
    `${area} Add record success`,
    props<{ record: Model }>()
);

// export const addRecordFail = createAction(
//     `${area} Add record failed`,
//     props<any>()
// );

/**
 * Update record actions
 */

export const updateRecord = createAction(
    `${area} Update record`,
    props<{ record: Model }>()
);

export const updateRecordSuccess = createAction(
    `${area} Update record success`,
    props<{ record: Model }>()
);

/**
 * Update config
 */

export const updateConfig = createAction(
    `${area} Update config`,
    props<{ record: Partial<Model> }>()
);

export const updateConfigSuccess = createAction(
    `${area} Update config success`,
  props<{ record: Partial<Model> }>()
);


/**
 * Delete record actions
 */

export const deleteRecord = createAction(
    `${area} Delete record`,
    props<{ id: number }>()
);

export const deleteRecordSuccess = createAction(
    `${area} Delete record success`,
    props<{ id: number }>()
);


/**
 * Set current record
 */

export const setCurrentRecord = createAction(
    `${area} Set current record`,
    props<{ id: number }>()
);

export const updatePrivacyFile = createAction(
    `${area} Update Privacy File`,
    props<{ id: number, file: any }>()
);


/**
 * Modal
 */

export const showModal = createAction(
    `${area} Open modal`,
    props<{ id?: number }>()
);

/**
 * Modal
 */

export const showConfigModal = createAction(
    `${area} Open config modal`,
    props<{ id?: number }>()
);

export const closeModal = createAction(
    `${area} Close modal`,
);

