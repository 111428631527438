import { Injectable } from '@angular/core';

@Injectable()

export class AppProvider {

    load() {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }
}
