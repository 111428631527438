import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';

@Injectable()
export class CalendarMonthViewHeaderComponentFormatter extends CalendarDateFormatter {

  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale).substr(0, 1).toUpperCase(); // use short week days
  }

}
