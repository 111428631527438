import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { reducer as listReducer } from './list/reducer';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FeatureEffects } from './list/effects';

import { reducer as errorsReducer } from './errors/reducer';
import { FeatureState } from './settings';
import { PreventivoModalComponent } from '../components/preventivo-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';

const reducers: ActionReducerMap<FeatureState> = {
    list: listReducer,
    errors: errorsReducer
}

@NgModule({
    declarations: [PreventivoModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        StoreModule.forFeature('preventivi', reducers),
        EffectsModule.forFeature([FeatureEffects]),
      NgSelectModule,
    ],
    entryComponents: [PreventivoModalComponent]
})

export class FeatureStoreModule {
}


