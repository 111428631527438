import {Component, ViewChild} from '@angular/core';
import {IModalDialog, IModalDialogButton} from '../../../../packages/ngx-modal-master';
import {FormArray, FormBuilder, FormControl, FormGroup, NgForm} from '@angular/forms';
import {FeatureService} from '../subfeatures/trattamento/feature.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/store/store.module';
import {getRecords} from '../../prodotti/store/list/selectors';
import {getTipologieTrattamento} from '../../../core/store/settings/selectors';
import {loadRecords as loadProducts} from '../../prodotti/store/list/actions';
import {loadRecords as loadTipologie} from '../../../core/store/settings/actions';

@Component({
  template: `
    <form [formGroup]="myForm">
      <div class="form-group row" [ngClass]="{'align-items-center': !edit}">
        <label class="col-form-label">Tipologia:</label>
        <div class="control-input">
          <div *ngIf="edit">
            <select formControlName="tipologia_id" class="form-control">
              <option [value]="null">Seleziona tipologia</option>
              <option *ngFor="let tipologia of (tipologie$ | async)" [value]="tipologia.id">{{tipologia.name}}</option>
            </select>
          </div>
          <div *ngIf="!edit">
            <div>{{ appuntamento.tipologia.name }}</div>
          </div>
        </div>
      </div>

      <div class="form-group row mb-0" [ngClass]="{'mt-3': !edit}" style="flex-wrap: nowrap">
        <label class="col-form-label">Prodotti:</label>
      </div>
      <div class="mb-3">
        <app-products-selector filtro="trattamento" [canEdit]="edit" (onAdd)="handleAddProduct($event)" (onRemove)="handleRemoveProduct($event)"
                               [productsList]="products$ | async" [selectedProducts]="myFormProducts.value"></app-products-selector>
      </div>

      <div class="form-group row">
        <label class="col-form-label">Note trattamento:</label>
        <div class="control-input">
          <textarea formControlName="note_trattamento" class="form-control"></textarea>
        </div>
      </div>

      <div>
        <label class="checkbox checkbox-primary">
          <input type="checkbox" formControlName="copia_scheda">
          <span></span>Copia scheda per i prossimi appuntamenti: modifiche applicate solo agli appuntamenti futuri pianificati
        </label>
      </div>

      <div *ngIf="myForm.value.copia_scheda" class="d-flex align-items-center">
        Inserisci per quanti appuntamenti vuoi copiare la scheda oltre a questa: <input style="margin-left: 10px; width: 60px" type="number"
                                                                                        class="form-control"
                                                                                        formControlName="copia_scheda_numero">
      </div>

      <div *ngIf="prevAppuntamento && prevAppuntamento.data" class="mt-3">
        <hr>
        <p><strong>Riepilogo precedente trattamento</strong></p>
        <p>
          <strong>Data:</strong>
          <ng-template ngIf="prevAppuntamento.data"> {{ prevAppuntamento.data | dateFormat: 'dd/MM/yyyy' }}</ng-template>
          <br>
          <strong>Tipologia:</strong> {{ prevAppuntamento.tipologia ? prevAppuntamento.tipologia.name : 'N/D' }}<br>
          <strong>Note:</strong> {{ prevAppuntamento.note_trattamento }}<br/>
          <strong>Prodotti:</strong> {{ prevAppuntamento.products.length ? (prevAppuntamento.products | productsNameList) : 'Nessuno' }}
        </p>
      </div>
    </form>

  `
})

export class SchedaTrattamentoModalComponent implements IModalDialog {

  @ViewChild('form', {static: true}) form: NgForm;
  myForm: FormGroup;
  myFormProducts: FormArray;
  actionButtons: IModalDialogButton[];
  appuntamento = null;
  prevAppuntamento = null;
  clienteId = null;
  edit = true;
  products$ = this.store.select(getRecords);
  tipologie$ = this.store.select(getTipologieTrattamento);

  constructor(
    private service: FeatureService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {

    this.myForm = fb.group({
      tipologia_id: fb.control(null),
      note_trattamento: fb.control(null),
      products: fb.array([]),
      copia_scheda: fb.control(false),
      copia_scheda_numero: fb.control(1)
    });

    this.myFormProducts = this.myForm.get('products') as FormArray;

    this.store.dispatch(loadProducts({}));
    this.store.dispatch(loadTipologie());

    this.handleSubmit = this.handleSubmit.bind(this);

  }

  dialogInit(reference, options) {

    this.clienteId = options.data.clienteId;
    this.appuntamento = {...options.data.appuntamento};

    this.myForm.patchValue(this.appuntamento);
    this.appuntamento.products.map(item => this.myFormProducts.push(new FormControl(item)));

    this.prevAppuntamento = {...options.data.prevAppuntamento};

    this.actionButtons = [
      {text: 'Chiudi', buttonClass: 'btn btn-default'},
      {text: 'Salva', buttonClass: 'btn btn-success', onAction: this.handleSubmit},
    ];

  }

  handleEdit() {

    this.edit = true;

  }

  handleSubmit() {

    return this.service.updateAppuntamento(this.clienteId, this.appuntamento.trattamento_id, this.appuntamento.id, {
      ...this.myForm.value,
      ignoreDate: true,
      products: this.myFormProducts.value.map(item => item.name),
    });

  }

  handleAddProduct(product) {

    if (product) {
      this.myFormProducts.push(new FormControl(product));
    }

  }

  handleRemoveProduct(removeIndex) {

    this.myFormProducts.removeAt(removeIndex);

  }

}
