import { createAction, props } from '@ngrx/store';
import { Token } from '../../../models/token';

export const login = createAction(
    '[Auth] Login',
    props<{ email: string, password: string }>()
)

export const loginSuccess = createAction(
    '[Auth] Login success',
    props<{token: Token, redirect: boolean}>()
)

export const loginFail = createAction(
    '[Auth] Login fail',
    props<{ error: any }>()
)

export const logout = createAction(
    '[Auth] Logout'
)

export const logoutSuccess = createAction(
    '[Auth] Logout success'
)

export const saveAuth = createAction(
    '[Auth] Save Auth',
    props<{ token: Token }>()
)




