import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-laravel-errors',
  styles: [`
    ul {
      margin: 5px 0 0 0;
    }
  `],
  template: `
    <div class="alert alert-danger" *ngIf="errors && errors.errors">
        <strong>{{ errors.message }}</strong><br>
        <ul>
          <li *ngFor="let item of errors.errors | keyvalue ">{{ item.value[0] }}</li>
        </ul>
    </div>
  `
})

export class LaravelErrorsComponent {

  @Input('errors') errors: any = null;

}
