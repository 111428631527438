import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productsNameList'
})
export class ProductsNameListPipe implements PipeTransform {

  transform(records: any[], separator: string = ', '): string {
    return records.map(record => record.name).join(separator);
  }

}
