
    <div class="PhotoBoot">
      <div class="PhotoBootWrapper">
        <div class="PhotoBootControls">
          <select class="form-control" [(ngModel)]="selectedDeviceId">
            <option [ngValue]="null">Seleziona camera</option>
            <option *ngFor="let device of devices; let i = index"
                    [value]="device.deviceId">{{device.label !== '' ? device.label : 'Camera ' + i }}</option>
          </select>
          <button (click)="searchDevices()" class="btn btn-default pt-1 pb-0"><i class="mdi mdi-refresh p-0 m-0"></i>
          </button>
        </div>
        <div class="PhotoBootTimer">
          Timer (secondi):
          <input type="number" [value]="timer" (change)="timer = $event.target['value']"/>
        </div>
        <button (click)="triggerSnapshot()" [disabled]="!selectedDeviceId"
                [ngClass]="{'btn-default': !selectedDeviceId, 'btn-success': selectedDeviceId}"
                class="PhotoBootScatta btn pt-2 pb-0"><i class="mdi mdi-camera p-0"></i> Scatta foto
        </button>
        <div class="PhotoBootVideo">
          <webcam
            [videoOptions]="{width: {ideal: 1280}, height: {ideal: 1024}}"
            width="1280"
            height="1024"
            [device]="selectedDeviceId"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
          ></webcam>
          <div #countdown class="PhotoBootVideo_Countdown">{{timer}}</div>
        </div>
      </div>

      <form (submit)="saveHandler()" [formGroup]="form" class="PhotoBootPhotos">
        <div class="PhotoBootPhotos_Top">
          <ng-select [items]="clienti" formControlName="cliente_id" bindValue="id" [searchFn]="customSearchFn"
                     placeholder="Seleziona cliente">
            <ng-template ng-label-tmp let-item="item">
              {{item.cognome}} {{item.nome}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.cognome}} {{item.nome}}
            </ng-template>
          </ng-select>

          <div class="mt-4">
            <label class="radio">
              <input type="radio" formControlName="cartella" value="new">
              <span></span>Nuova cartella
            </label>
          </div>
          <div>
            <label class="radio">
              <input type="radio" formControlName="cartella" value="id">
              <span></span>Cartella esistente
            </label>
          </div>

          <select formControlName="id" class="form-control mt-1" *ngIf="form.value.cartella === 'id'">
            <option [ngValue]="null">Seleziona cartella</option>
            <option *ngFor="let cartella of cartelle" [value]="cartella.id">{{cartella.nome}}</option>
          </select>
          <input *ngIf="form.value.cartella === 'new'" type="text" class="form-control mt-1"
                 placeholder="Inserisci nome cartella" formControlName="nome">
        </div>
        <div class="PhotoBootPhotos_List">
          <foto-item *ngFor="let item of images; let imageIndex = index" [item]="item"
                     (onImageClick)="imageClickHandler($event, imageIndex)"
                     (onUpdate)="handleUpdate($event)"></foto-item>
        </div>
        <div class="PhotoBootPhotos_Bottom">
          <button (click)="onClose.emit()" type="button" class="btn btn-danger">Chiudi</button>
          <button [disabled]="form.invalid || !this.images.length" type="submit" class="btn btn-primary">Salva
            cartella
          </button>
        </div>
      </form>

      <div *ngIf="showImage" class="OverlayPhoto">
        <img src="{{showImage.fullUrl}}"/>
        <div class="OverlayPhoto_Buttons">
          <button (click)="deleteHandler(showImage)" class="btn btn-danger">Elimina</button>
          <button (click)="showImage = null" class="btn btn-success">OK</button>
        </div>
      </div>

    </div>
  