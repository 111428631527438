import { Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

export interface SelectedFile {
  base64: string | ArrayBuffer;
  fileItem: File;
  tempUrl: string;
}

@Component({
    selector: 'app-file-upload',
    styles: [`
        .InputContainer {
            position: relative;
            overflow: hidden;
        }
        .InputContainer div {
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: none;

        }
        .InputContainer div span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            /*max-width: 245px;*/
            display: block;
        }
        .InputContainer input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
        }
    `],
    template: `
        <div class="InputContainer">
            <div class="btn btn-primary" [ngClass]="{'btn-sm': btnSmall}"><span><i [class]="icon"></i> {{label}}</span></div>
            <input multiple class="form-control" #file type="file" name="{{ name }}" (change)="processFile()"><!--accept="image/*" -->
        </div>
    `
})

export class FileUploadComponent {

    @Input() btnSmall = false;
    @Input() label = 'Seleziona immagine';
    @Input() showSelectedFileName = false;
    @Input() icon: 'flaticon2-image-file';
    @Input() name = 'file';
    @Input() absolute = false;
    @Input() className;
    @ViewChild('file', { static: false }) file;
    @Output() selected: EventEmitter<SelectedFile> = new EventEmitter();
    selectedFile = false;

    processFile() {

        for(let i = 0; i < this.file.nativeElement.files.length; i++) {
            const fileItem: File = this.file.nativeElement.files[i];
            const reader = new FileReader();
            reader.readAsDataURL(fileItem);

            if (this.showSelectedFileName) {
                this.label  = `File: ${fileItem.name}`;
            }

            reader.addEventListener('load', (event) => {
                this.selected.emit({base64: reader.result, fileItem, tempUrl: event.target['result']});
                this.file.nativeElement.value = '';
                this.selectedFile = true;
            });
        }

    }
}
