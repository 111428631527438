import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable, Observer, Subscription } from 'rxjs';


@Component({
    selector: 'app-dob-input',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DobComponent),
            multi: true
        }
    ],
    styles: [`
        .DobInput {
            display: flex;
        }
        .DobInput_Giorno {
            width: 92px;
            border-radius: 0.42rem 0 0 0.42rem;
        }
        .DobInput_Mese {
            flex: 1;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }
        .DobInput_Anno {
            width: 80px;
            border-radius: 0 0.42rem 0.42rem 0
        }
    `],
    template: `
        <div class="DobInput" [formGroup]="form">
            <select class="form-control DobInput_Giorno" formControlName="giorno">
                <option value="">Giorno</option>
                <option *ngFor="let giorno of giorni" [value]="giorno">{{giorno}}</option>
            </select>
            <select class="form-control DobInput_Mese" formControlName="mese">
                <option value="">Mese</option>
                <option *ngFor="let mese of mesi" [value]="mese">{{mese}}</option>
            </select>
            <input type="number" formControlName="anno" placeholder="Anno" class="form-control DobInput_Anno">
        </div>
    `
})

export class DobComponent implements ControlValueAccessor, OnDestroy {

    form: FormGroup;
    @Input('value') _value = false;
    subscribe: Subscription;
    onChange = (value: any) => {};

    giorni = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
    mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

    constructor(fb: FormBuilder) {

        this.form = fb.group({
            giorno: fb.control(null, [Validators.required]),
            mese: fb.control(null, [Validators.required]),
            anno: fb.control(null, [Validators.required]),
        })

        this.subscribe = this.form.valueChanges.subscribe((value) => {
            if (this.form.valid) {
                const monthIndex = ('0' + (this.mesi.findIndex(mese => mese === value.mese) + 1)).substr(-2);
                const dayValue = ('0' + value.giorno).substr(-2);
                const date = `${value.anno}-${monthIndex}-${dayValue}`;
                this.onChange(date);
            }
        })

    }

    ngOnDestroy(): void {
        this.subscribe.unsubscribe();
    }

    onTouched = () => {}

    writeValue(value: string): void {
        if (value) {
            const exploded = value.split('-');
            const patchValue = {
                giorno: parseInt(exploded[2]),
                mese: this.mesi[parseInt(exploded[1]) - 1],
                anno: parseInt(exploded[0]),
            }
            console.log(value, patchValue);
            this.form.patchValue(patchValue);
        }
        // this.level = obj;
    }

    registerOnChange(fn: any): void{
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

}