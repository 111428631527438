import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { getPermissions } from '../../core/store/account/selectors';
import { AppState } from '../../core/store/store.module';
import { filter, tap } from 'rxjs/operators';

@Directive({
  selector: "[hasPermission]",
})
export class HasPermissionDirective implements OnInit{
  @Input("permission") permission: string;

  userPermissions: string[] = [];

  constructor(private elementRef: ElementRef, private store: Store<AppState>) {
  }

  ngOnInit() {

    this.elementRef.nativeElement.style.display = "none";

    this.store.select(getPermissions).pipe(
      filter((response) => response && response.length > 0)
    ).subscribe((permissions: string[]) => {
      this.userPermissions = permissions;
      this.checkAccess()
    });

  }

  checkAccess() {
    let display = 'block';
    if (this.elementRef.nativeElement.tagName == 'TH' || this.elementRef.nativeElement.tagName == 'TD') {
      display = 'table-cell'
    }
    this.elementRef.nativeElement.style.display = this.userPermissions.find(permission => this.permission === permission) ? display : "none";
  }
}
