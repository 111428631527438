

    <div class="modal-header">
      <h4 class="modal-title">Configurazione</h4>
    </div>
    <div class="modal-body">

      <p>
        <strong>ID Utente</strong>: {{ record?.id }}
      </p>

      <form [formGroup]="form" action="">

        <div *ngIf="errors && errors.message" class="alert alert-danger">{{ errors.message }}</div>


        <div formGroupName="smshosting">

          <h3 class="mb-4">Configurazione Smshosting</h3>

          <div class="form-group row">
            <label class="col-form-label">Status</label>
            <div class="control-input">
              <select formControlName="status" class="form-control">
                <option value="">Seleziona</option>
                <option value="active">Attivo</option>
                <option value="pending">Non attivo</option>
              </select>
              <span *ngIf="errors?.username" class="invalid-feedback" role="alert">{{ errors?.username[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Username</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="username"
                     name="username"
                     [ngClass]="{'is-invalid': errors?.username }">
              <span *ngIf="errors?.username" class="invalid-feedback" role="alert">{{ errors?.username[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Password</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="password"
                     name="password"
                     [ngClass]="{'is-invalid': errors?.password }">
              <span *ngIf="errors?.password" class="invalid-feedback" role="alert">{{ errors?.password[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">API KEY</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="api_key"
                     name="api_key"
                     [ngClass]="{'is-invalid': errors?.api_key }">
              <span *ngIf="errors?.api_key" class="invalid-feedback" role="alert">{{ errors?.api_key[0] }}</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">API Secret</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="api_secret"
                     name="api_secret"
                     [ngClass]="{'is-invalid': errors?.api_secret }">
              <span *ngIf="errors?.api_secret" class="invalid-feedback" role="alert">{{ errors?.api_secret[0] }}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label">Sender</label>
            <div class="control-input">
              <input class="form-control" type="text" formControlName="sender"
                     name="sender"
                     [ngClass]="{'is-invalid': errors?.sender }">
              <span *ngIf="errors?.sender" class="invalid-feedback" role="alert">{{ errors?.sender[0] }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
      <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
    </div>
  