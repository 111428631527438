/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./simple-modal.component";
var styles_SimpleModalComponent = ["[_nghost-%COMP%] { display: block; }"];
var RenderType_SimpleModalComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SimpleModalComponent, data: {} });
export { RenderType_SimpleModalComponent as RenderType_SimpleModalComponent };
export function View_SimpleModalComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SimpleModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "simple-modal-dialog", [], [[8, "innerHTML", 1]], null, null, View_SimpleModalComponent_0, RenderType_SimpleModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.SimpleModalComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).text; _ck(_v, 0, 0, currVal_0); }); }
var SimpleModalComponentNgFactory = i0.ɵccf("simple-modal-dialog", i1.SimpleModalComponent, View_SimpleModalComponent_Host_0, {}, {}, []);
export { SimpleModalComponentNgFactory as SimpleModalComponentNgFactory };
