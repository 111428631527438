// pagination.component.ts
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-pagination',
    styles: [`
        .pagination-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @media(min-width: 768px) {
          ._mobile {
            display: none;
          }
        }
        @media(max-width: 767px) {
          ._desktop {
            display: none;
          }
        }
    `],
    template: `
        <div class="pagination-container">
            <div class="pagination-container__left">
                <ul class="pagination" *ngIf="count > 0">
                    <li *ngIf="current > 0" class="page-item"><button class="page-link" (click)="onFirst()">
                      <span class="_mobile">&laquo;</span>
                      <span class="_desktop">Prima</span>
                    </button></li>
                    <li class="page-item" *ngIf="!(current === 0 || loading)"><button class="page-link" (click)="onPrev()" [ngClass]="{ 'disabled': current === 1 || loading }">
                      <span class="_mobile">&lt;</span>
                      <span class="_desktop">Precedente</span>
                    </button></li>
                    <li class="page-item"
                        *ngFor="let pageNum of getPages()"
                        (click)="onPage(pageNum)"
                        [ngClass]="{'active': pageNum === current, 'disabled': loading}"
                    >
                        <button class="page-link" (click)="onPage(pageNum)">{{ pageNum + 1 }}</button>
                    </li>
                    <li class="page-item" *ngIf="!(lastPage() || loading)"><button class="page-link" (click)="onNext(true)" [ngClass]="{ 'disabled': lastPage() || loading }">
                      <span class="_mobile">&gt;</span>
                      <span class="_desktop">Seguente</span>
                    </button></li>
                    <li class="page-item" *ngIf="current < getPagesCount()"><button class="page-link" (click)="onLast()">
                      <span class="_mobile">&raquo;</span>
                      <span class="_desktop">Ultima</span>
                    </button></li>
                </ul>
            </div>
            <div class="pagination-container__right">
                {{ count }} risultati
            </div>
        </div>
    `
})
export class PaginationComponent {
    @Input() current: number = 0;
    @Input() count: number = 0;
    @Input() perPage: number = 10;
    @Input() loading: boolean = false;
    @Input() pagesToShow: number = 5;
    // @Input() changePage;
    @Input() changePagination;

    @Output() changePage = new EventEmitter<number>();
    @Output() goPrev = new EventEmitter<boolean>();
    @Output() goNext = new EventEmitter<boolean>();
    @Output() goPage = new EventEmitter<number>();

    constructor() { }

    getMin(): number {
        return ((this.perPage * this.current) - this.perPage) + 1;
    }

    getMax(): number {
        let max = this.perPage * this.current;
        if (max > this.count) {
            max = this.count;
        }
        return max;
    }

    onPage(n: number): void {
        this.changePage.emit(n);
    }

    onPrev(): void {
        this.changePage.emit(this.current - 1);
    }

    onNext(next: boolean): void {
        this.changePage.emit(this.current + 1);
    }

    totalPages(): number {
        return Math.ceil(this.count / this.perPage) || 0;
    }

    lastPage(): boolean {
        const c = this.getPagesCount();
        return this.current >= c;
    }

    getPages(): number[] {
        const c = this.getPagesCount();
        const p = this.current;
        const pagesToShow = this.pagesToShow || 9;
        const pages: number[] = [];
        pages.push(p);
        const times = pagesToShow - 1;
        for (let i = 0; i < times; i++) {
            if (pages.length < pagesToShow) {
                if (Math.min.apply(null, pages) > 0) {
                    pages.push(Math.min.apply(null, pages) - 1);
                }
            }
            if (pages.length < pagesToShow) {
                if (Math.max.apply(null, pages) < c) {
                    pages.push(Math.max.apply(null, pages) + 1);
                }
            }
        }
        pages.sort((a, b) => a - b);
        return pages;
    }

    onFirst() {

        this.changePage.emit(0);

    }

    onLast() {

        this.changePage.emit(this.getPagesCount());

    }

    getPagesCount() {
        return Math.ceil(this.count / this.perPage) - 1;
    }
}
