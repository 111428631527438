
    <div *ngIf="settings.overlayClass && showOverlay" [ngClass]="[settings.overlayClass, animateOverlayClass]"></div> 
    <div [ngClass]="[settings.modalClass, animateModalClass]" #dialog>
      <div [ngClass]="settings.modalDialogClass">
        <div [ngClass]="[ showAlert ? settings.alertClass : '', settings.contentClass]">
          <div [ngClass]="settings.headerClass">
            <h4 [ngClass]="settings.headerTitleClass"><strong>{{title}}</strong></h4>
            <button (click)="close()" *ngIf="!actionButtons || !actionButtons.length" type="button"
                    [title]="settings.closeButtonTitle"
                    [ngClass]="settings.closeButtonClass">
            </button>
          </div>
          <div [ngClass]="settings.bodyClass">
            <i #modalDialogBody></i>
          </div>
          <div [ngClass]="settings.footerClass" *ngIf="actionButtons && actionButtons.length">
            <button *ngFor="let button of actionButtons" (click)="doAction(button.onAction)"
                    [ngClass]="button.buttonClass || settings.buttonClass">{{button.text}}
            </button>
          </div>
        </div>
      </div>
    </div>
  