import { createAction, props } from '@ngrx/store';

export const toggleLoading = createAction(
    '[UI] Toggle loading',
    props<{value: boolean}>()
)

export const toggleMenu = createAction(
    '[UI] Toggle menu',
    props<{value: boolean}>()
)